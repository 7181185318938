function setSizes() {
    const produnctPhotoContainers = document.querySelectorAll<HTMLElement>('.js-product-photo');

    produnctPhotoContainers.forEach(produnctPhotoContainer => {
        const productPhoto = produnctPhotoContainer.querySelector<HTMLImageElement>('.js-product-photo-img');
        const productButtons = produnctPhotoContainer.querySelector<HTMLElement>('.js-product-buttons');

        if (productButtons && productPhoto) {
            const ratioImage = productPhoto.naturalHeight / productPhoto.naturalWidth;
            productButtons.style.width = `${productPhoto.clientWidth}px`;
            productButtons.style.height = `${productPhoto.clientWidth * ratioImage}px`;
        }
    })
}

export default function buttonsProduct() {

    if (window.matchMedia('(min-width: 1025px').matches) {
        setTimeout(() => {
            setSizes();
        }, 2);
        window.addEventListener("resize", setSizes);
    }
}
