import serialize from 'form-serialize';
import delegate from 'delegate';
import axios from 'axios';

let delegation: any;

function fetchLocation(event: any) {
    const target = event.delegateTarget as HTMLAnchorElement;
    const counter = target.querySelector<HTMLElement>('.js-likes-counter');
    const url = target.dataset.urlRequest;

    const dataForm = {
        idRecipe: target.dataset.idRecipe
    }

    if (url && counter && !target.classList.contains('is-like')) {
        getRequest( url, dataForm, counter, target);
    }
}

export async function getRequest(url: string, dataForm: any = '', counter: HTMLElement, target: HTMLElement) {
    // const containerContent = formFilters.closest('.js-location');
    // containerContent?.classList.add('chps-content-list--loading');

    const response = await axios.get(url, {
        params: dataForm,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
    })
        .then(response => response.data)
        .then(data => {
            if (data.success) {
                if (counter.textContent) {
                    const count = +counter.textContent;
                    counter.innerHTML = `${count + 1}`;
                }
                target.classList.add('is-like');
            }
        })
        .catch((err) => {
            if (err.name !== 'AbortError') {
                throw err;
            }
        })
        .finally(() => {
            // setTimeout(() => {
            //     containerContent?.classList.remove('is-loading');
            // }, 300);
        });
}

function init() {
    delegation = delegate(document, '.js-recipe-like', 'click', fetchLocation);
}

const _module = { init };

export default _module;






