import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

const TriggerSelector: string = '[data-catalog-filter]';
let activeTab: number;

function onClick(this: HTMLElement) {
    setFilters(this);
}

function setFilters(el: HTMLElement) {
    const valueTab = el.dataset.catalogFilter;
    const container = el.closest('.js-catalog-container');
    const contentArr = container?.querySelectorAll<HTMLElement>('[data-catalog-content]');
    const contentContainer = container?.querySelector<HTMLElement>('.js-catalog-content-container');

    const filterContent = () => {
        if (el.dataset.catalogFilter == 'all') {
            contentArr?.forEach(content => content.classList.remove('is-hide'));
            return;
        }

        contentArr?.forEach(content => content.classList.add('is-hide'));

        contentArr?.forEach((content, index) => {
            const valueContent = content.dataset.catalogContent;

            if (valueTab && valueContent && valueContent == valueTab) {
                content.classList.remove('is-hide');
            }
        });
    }

    if (window.matchMedia('(max-width: 650px)').matches) {
        filterContent();
    } else {
        contentContainer?.classList.add('is-load');

        setTimeout(() => {
            filterContent();
            contentContainer?.classList.remove('is-load');
        }, 500);
    }
}

function setOldFilters(activeTab: HTMLInputElement) {

    const valueTab = activeTab.dataset.catalogFilter;
    const container = activeTab.closest('.js-catalog-container');
    const contentArr = container?.querySelectorAll<HTMLElement>('[data-catalog-content]');

    if (activeTab.dataset.catalogFilter == 'all') {
        contentArr?.forEach(content => content.classList.remove('is-hide'));
        return;
    }

    contentArr?.forEach(content => content.classList.add('is-hide'));

    contentArr?.forEach((content, index) => {
        const valueContent = content.dataset.catalogContent;

        if (valueTab && valueContent && valueContent == valueTab) {
            content.classList.remove('is-hide');
        }
    });
}

function saveValues(this: HTMLElement) {
    const container = this.closest('.js-catalog-container');
    const tabTriggers = container?.querySelectorAll<HTMLInputElement>(TriggerSelector);

    tabTriggers?.forEach((tab, i) => {
        if (tab.checked) activeTab = i;
    });
}

function setActiveValue(this: HTMLElement) {
    const container = this.closest<HTMLElement>('.js-catalog-container');
    const tabTriggers = container?.querySelectorAll<HTMLInputElement>(TriggerSelector);

    tabTriggers?.forEach(tab => tab.checked = false);

    if (tabTriggers && tabTriggers[activeTab]) {
        tabTriggers[activeTab].checked = true;
        setOldFilters(tabTriggers[activeTab]);
    }

    if (container) setTitle(container);
}

function setTitle(container: HTMLElement | null) {
    if (!container) return;

    const titleFilters = container.querySelector<HTMLElement>('.js-filters-title');
    const filters = container.querySelectorAll<HTMLElement>(TriggerSelector);

    if (titleFilters && filters && activeTab != null) {
        const index = activeTab;
        const filterValue = filters[index].dataset.filterValue;
        if (filterValue) {
            titleFilters.textContent = `${filterValue}`;
        }
    }
}

function init() {
    const tabTriggers = Array.from(document.querySelectorAll<HTMLInputElement>(TriggerSelector));
    const popup = document.querySelector<LitPopupElement>('[data-lit-popup="catalog_filters"]');
    const acceptFilters = popup?.querySelector<HTMLElement>('.js-filters-accept');

    tabTriggers.forEach(tab => tab.addEventListener('change', onClick));
    popup?.addEventListener('open', saveValues);
    popup?.addEventListener('close-complete', setActiveValue);
    acceptFilters?.addEventListener('click', () => {
        tabTriggers?.forEach((tab, i) => {
            if (tab.checked) activeTab = i;
        })
        popup?.close();
    });

    tabTriggers?.forEach((tab, i) => {
        if (tab.checked) activeTab = i;
    });

    tabTriggers.filter((input) => input.checked).forEach(input => {
        const container = input.closest<HTMLElement>('.js-catalog-container');
        setFilters(input);
        setTitle(container);
    });
}

const _module = { init };

export default _module;
