import Lazyload from 'vanilla-lazyload';
import buttonsProduct from './buttons-product';

export const lazyload = new Lazyload({
    elements_selector: '.lazy',
    threshold: 700,
    callback_enter: callback
});

function callback(el: HTMLElement) {
    if (el.classList.contains('js-product-photo-img')) {
        buttonsProduct();
    }
}
