import ContentList from '@chipsadesign/content-list';
import cursorProduct from './cursor-product';
import hoverButton from '../animations/hover-button';
import video from './video';
import reveal from '../animations/reveal';

function init() {
    const catalogElArr = document.querySelectorAll<HTMLElement>('.js-catalog');

    catalogElArr.forEach(catalogEl => {
        if (catalogEl) {
            const contentList = new ContentList(catalogEl, {
                pushState: false,
                onFetchSuccess: (instance, response) => {
                    if (instance.contentEl) instance.contentEl.innerHTML += response.data.contentHtml;

                    const paginationTrigger = catalogEl.querySelector<HTMLElement>('.chps-content-list-pagination-link');
                    const paginationElement = paginationTrigger?.closest<HTMLElement>('.product-item__more-position');

                    if (paginationElement) paginationElement.remove();

                    cursorProduct();
                    hoverButton();
                    video.init();
                    reveal.init();
                },
            });
        }
    })
}

const _module = { init };

export default _module;
