import gsap from 'gsap';

export default function cursor() {
    const products = document.querySelectorAll('.js-product-link');

    let cursor = document.querySelector<HTMLElement>(".js-cursor");

    let mouseX = 0,
        mouseY = 0;

    const tl = gsap.timeline();
    tl.to({}, 0.016, {
      repeat: -1,
      onRepeat: function() {

        gsap.set(cursor, {
            left: mouseX - 75,
            top: mouseY - 75,
            ease: "none"
        });
      }
    });

    document.addEventListener("mousemove", function(e) {
        mouseX = e.clientX;
        mouseY = e.clientY;
    });

    products.forEach(product => {
        product.addEventListener("mouseenter", function(e) {
            mouseX = e.clientX;
            mouseY = e.clientY;
            cursor?.classList.add('is-show');

        });
        product.addEventListener("mouseleave", function(e) {
            cursor?.classList.remove('is-show');
        });

        const buttons = document.querySelectorAll<HTMLElement>('.js-product-button');
        const noHoverElements = document.querySelectorAll<HTMLElement>('.js-no-hover-button');

        buttons.forEach(button => initHover(button));
        noHoverElements.forEach(el => initHover(el));

        function initHover(el: HTMLElement) {
            el.addEventListener('mouseenter', () => {
                cursor?.classList.remove("is-show");
            });
            el.addEventListener('mouseleave', () => {
                cursor?.classList.add("is-show");
            });
        }
    })
}
