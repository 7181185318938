import Swiper, { Navigation } from 'swiper';

Swiper.use([ Navigation ]);

const SELECTOR = '.js-video-slider';

const map = new WeakMap<HTMLElement, any>();

function init(container: HTMLElement | Document = document) {

    const arrSlider = container.querySelectorAll<HTMLElement>(SELECTOR);

    arrSlider.forEach(slider => {

        if (window.matchMedia('(max-width: 850px)').matches) {
            const swiper = new Swiper(slider, {
                slidesPerView: 1.2,
                speed: 400,
                spaceBetween: 20,
                breakpoints: {
                    651: {
                        slidesPerView: 2.1,
                        spaceBetween: 20,
                    }
                }
            });

            map.set(slider, swiper);
        }
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>(SELECTOR)).forEach((el) => {
        const slider = map.get(el);

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
