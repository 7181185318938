import gsap from 'gsap';
import { timeout } from '../utils/timeout';

export const preloaderAnimationDuration = 0.7; // sec
let video: HTMLVideoElement | null;

function init() {
    const header = document.querySelector('.js-header');
    const main = document.querySelector('.js-main');
    const isMobile = window.matchMedia('(max-width: 1024px)').matches;

    function createPreloader() {
        const preloader = document.querySelector<HTMLElement>('.js-preloader');
        loadAdaptiveVideo();

        const state = {
            completed: false,
        };

        document.body.classList.add('no-scroll');

        function leave(): Promise<void> {
            return new Promise((resolve) => {
                document.body.classList.remove('no-scroll');

                document.dispatchEvent(new Event('preloader-leave'));

                const tl = gsap.timeline();

                tl.to(
                    preloader,
                    {
                        duration: 0.5,
                        opacity: 0,
                        onComplete: () => {
                            preloader?.classList.add('preloader--hidden');
                            state.completed = true;
                        },
                    },
                    0.5,
                ).add(resolve, 0.65);

                if (video) {
                    // video.pause();
                    // video.currentTime = 0;
                    // video.muted = true;

                    document.documentElement.addEventListener(
                        'header-animation-complete',
                        () => {
                            if (video) {
                                // video.pause();
                                // video.currentTime = 0;

                                if (!isMobile) {
                                    video.play();
                                }

                                if (video.paused) {
                                    const triggerContainer = document.querySelector('.js-main-video-trigger-container');
                                    if (triggerContainer) triggerContainer.classList.add('is-show');
                                }
                            }
                        },
                        { once: true },
                    );
                }
            });
        }

        function loadAdaptiveVideo() {
            const videoContainer = document.querySelector<HTMLElement>('.js-video-container-desktop');

            if (videoContainer) {
                const { videoSrcDesktop, videoSrcMobile } = videoContainer.dataset;

                if (videoSrcDesktop && videoSrcMobile) {
                    let videoSrc = isMobile ? videoSrcMobile : videoSrcDesktop;
                    video = createIframe(videoSrc);
                    videoContainer.appendChild(video);

                    if (video.readyState === 4) {
                        if (isMobile) {
                            video.play();
                            // video.pause();
                        }
                    } else {
                        video.addEventListener(
                            'canplay',
                            () => {
                                if (video) {
                                    if (isMobile) {
                                        video.play();
                                        // video.pause();
                                    }
                                }
                            },
                            { once: true },
                        );
                    }
                }
            }

            function createIframe(id: string) {
                const video = document.createElement('video');
                // const source = document.createElement('source');
                video.classList.add('responsive');
                // video.setAttribute('loading', 'lazy');
                // video.setAttribute('allowfullscreen', '');
                video.muted = true;
                video.autoplay = true;
                video.loop = true;
                video.playsInline = true;
                video.src = id;
                // source.setAttribute('src', id);
                // video.appendChild(source);
                return video;
            }
        }

        function loadAsset(asset: HTMLImageElement | HTMLVideoElement): Promise<void> {
            return new Promise((resolve) => {
                if (asset instanceof HTMLImageElement) {
                    if (asset.complete) {
                        resolve();
                    } else {
                        asset.onload = () => resolve();
                        asset.onerror = () => resolve();
                    }
                }

                if (asset instanceof HTMLVideoElement) {
                    if (asset.readyState === 4) {
                        resolve();
                    } else {
                        asset.addEventListener('canplay', () => resolve(), { once: true });
                    }
                }
            });
        }

        async function loadAssetsFromElement(element: Element | Document = document) {
            const images = Array.from(
                element.querySelectorAll<HTMLImageElement>('img:not(.lazy):not([loading="lazy"])'),
            );
            const videos = Array.from(
                element.querySelectorAll<HTMLImageElement>('video:not(.lazy):not([loading="lazy"])'),
            );
            const assets: Array<HTMLImageElement | HTMLVideoElement> = [...images, ...videos];

            if (images.length > 0) {
                await Promise.all<any>(assets.map((asset) => loadAsset(asset)));
            }
        }

        async function loadAssets() {
            await loadAssetsFromElement(document.body);
        }

        return { leave, loadAssets, state } as const;
    }

    const preloaderInstance = createPreloader();
    main?.classList.add('is-hidden');
    gsap.set(header, { yPercent: -100, opacity: 0 });

    // Initial load
    preloaderInstance
        .loadAssets()
        .then(() => timeout(1000))
        .then(() => preloaderInstance.leave())
        .then(() => {
            gsap.to(header, { yPercent: 0, opacity: 1, duration: 0.8, delay: 0.8 });

            setTimeout(() => {
                main?.classList.remove('is-hidden');
            }, 300);
        });
}

export default { init };
