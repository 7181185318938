import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

const TriggerSelector: string = '[data-map-filter]';
let activeTabs: number[];

function onClick(this: HTMLInputElement) {
    setFilters(this);
}

// фильтруем марукры
function setFilters(el: HTMLInputElement) {
    const container = el.closest('.js-map-container');
    const tabTriggers = container?.querySelectorAll<HTMLInputElement>('[data-map-filter]');
    const tabTriggerAll = container?.querySelector<HTMLInputElement>('[data-map-filter="all"]');
    const tabs = container?.querySelectorAll<HTMLElement>('[data-filter-map-content]');
    const mapMarkers = container?.querySelectorAll<HTMLElement>('.js-map-marker');

    mapMarkers?.forEach(marker => {
        marker.classList.remove('is-active');
    });

    tabs?.forEach((content, index) => {
        content.classList.remove('is-active');
    });

    // если выбран фильтр "Все линейки", убираем чекбокс с других фильтров, и наоборот
    if (el.dataset.mapFilter == 'all') {
        tabTriggers?.forEach(checkbox => {
            if (checkbox !== el) {
                checkbox.checked = false;
            }
        })
    } else {
        if (tabTriggerAll && tabTriggerAll.checked) {
            tabTriggerAll.checked = false;
        }
    }

    // триггерим вызов функции для карты
    container?.dispatchEvent(new Event('setFilters'));
}

// определяем, как фильтры активны и вносим их индексы в массив
function saveValues(this: HTMLElement) {
    const container = this.closest('.js-map-container');
    const tabTriggers = container?.querySelectorAll<HTMLInputElement>(TriggerSelector);

    activeTabs = [];

    tabTriggers?.forEach((tab, i) => {
        if (tab.checked) activeTabs.push(i);
    });
}

// для мобилки - при закрытии попапа с фильтрами, применяем их
function setActiveValue(this: HTMLElement) {
    const container = this.closest<HTMLElement>('.js-map-container');
    const tabTriggers = container?.querySelectorAll<HTMLInputElement>(TriggerSelector);

    tabTriggers?.forEach(tab => tab.checked = false);

    for (let i = 0; i < activeTabs.length; i++) {
        const index = activeTabs[i];

        if (tabTriggers && tabTriggers[index]) {
            tabTriggers[index].checked = true;

            setFilters(tabTriggers[index]);
        }
    }

    if (container) setTitle(container);
}

// устанавливаем заголовок фильтров для мобилки
function setTitle(container: HTMLElement | null) {
    if (!container) return;

    const titleFilters = container.querySelector<HTMLElement>('.js-filters-title');
    const filters = container.querySelectorAll<HTMLElement>(TriggerSelector);

    if (titleFilters && filters && activeTabs.length > 0) {
        const index = activeTabs[0];
        if (activeTabs.length == 1) {
            const filterValue = filters[index].dataset.filterValue;
            if (filterValue) {
                titleFilters.textContent = `${filterValue}`;
            }
        } else {
            const filterValue = filters[index].dataset.filterValue;
            if (filterValue) {
                titleFilters.textContent = `${filterValue}  +${activeTabs.length - 1}`;
            }
        }
    }
}

function init() {
    const tabTriggers = Array.from(document.querySelectorAll<HTMLInputElement>(TriggerSelector));
    const popup = document.querySelector<LitPopupElement>('[data-lit-popup="map_filters"]');
    const acceptFilters = popup?.querySelector<HTMLElement>('.js-filters-accept');
    const map = document.querySelector<HTMLElement>('app-yandex-map');

    tabTriggers.forEach(tab => tab.addEventListener('change', onClick));
    popup?.addEventListener('open', saveValues);
    popup?.addEventListener('close-complete', setActiveValue);
    acceptFilters?.addEventListener('click', () => {
        activeTabs = [];
        tabTriggers?.forEach((tab, i) => {
            if (tab.checked) activeTabs.push(i);
        })
        popup?.close();
    });

    map?.addEventListener('init', () => {
        activeTabs = [];
        setTimeout(() => {
            tabTriggers?.forEach((tab, i) => {
                if (tab.checked) activeTabs.push(i);
            });

            tabTriggers.filter((input) => input.checked).forEach(input => {
                const container = input.closest<HTMLElement>('.js-map-container');
                setFilters(input);
                setTitle(container);
            });
        }, 100);
    })
}

const _module = { init };

export default _module;
