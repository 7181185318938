import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';
let btnTimeout: NodeJS.Timeout;

function onOpen(this: HTMLElement) {
    const dataAttribute: string | undefined = this.dataset.litPopup;

    const toggleBtn = document.querySelector<HTMLElement>(`.js-toggle-lit-btn[data-lit-popup-open="${dataAttribute}"]`);

    if (toggleBtn && dataAttribute) {
        toggleBtn.classList.add('is-active');
        btnTimeout = setTimeout(() => {
            toggleBtn.removeAttribute('data-lit-popup-open');
            toggleBtn.setAttribute('data-lit-popup-close', dataAttribute);
        }, 1);
    }
}

function onCloseComplete(this: HTMLElement) {
    clearTimeout(btnTimeout);
    const dataAttribute: string | undefined = this.dataset.litPopup;

    const toggleBtn = document.querySelector<HTMLElement>(`.js-toggle-lit-btn[data-lit-popup-close="${dataAttribute}"]`);

    if (toggleBtn && dataAttribute) {
        toggleBtn.classList.remove('is-active');
        toggleBtn.removeAttribute('data-lit-popup-close');
        toggleBtn.setAttribute('data-lit-popup-open', dataAttribute);
    }
}

function init() {
    const toggleElArr = document.querySelectorAll<LitPopupElement>('.js-toggle-lit');

    toggleElArr.forEach(toggleEl => {
        if (toggleEl) {
            toggleEl.addEventListener('open', onOpen);
            toggleEl.addEventListener('close-complete', onCloseComplete);
        }
    })
}

const _module = { init };

export default _module;
