import Swiper, { Navigation } from 'swiper';

Swiper.use([ Navigation ]);

const SELECTOR = '.js-review-slider';

const map = new WeakMap<HTMLElement, any>();

function init(container: HTMLElement | Document = document) {

    const arrSlider = container.querySelectorAll<HTMLElement>(SELECTOR);

    arrSlider.forEach(slider => {
        const navigation = container.querySelector<HTMLElement>('.js-reviews-navigation');
        const slides = slider.querySelectorAll<HTMLElement>('.swiper-slide');
        if (slides.length > 1) {
            if (navigation) createNavigation(navigation);
        }
        const prevBtn = navigation?.querySelector<HTMLElement>('.js-slide-prev');
        const nextBtn = navigation?.querySelector<HTMLElement>('.js-slide-next');


        let swiper = new Swiper(slider, {
            slidesPerView: 1,
            speed: 400,
            spaceBetween: 20,
            navigation: {
                nextEl: nextBtn,
                prevEl: prevBtn,
                disabledClass: 'is-disable',
            },
            breakpoints: {
                651: {
                    slidesPerView: 2.1,
                    spaceBetween: 20,
                },
                1025: {
                    slidesPerView: 2.8,
                    spaceBetween: 40,
                },
                1441: {
                    slidesPerView: 2.2,
                    spaceBetween: 85,
                }
            }
        });

        map.set(slider, swiper);
    });
}

function createNavigation(parent: HTMLElement) {
    parent.innerHTML = `
        <button class="recipes-slider__button button-round-mask js-slide-prev" disabled aria-label="предыдущий слайд">
            <span class="button-round__icon">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.7068 1.40499C12.8356 1.29733 12.9101 1.13809 12.9101 0.970211C12.9101 0.536524 12.4428 0.263597 12.065 0.47667L0.603327 6.94199C-0.0873124 7.33157 -0.0714857 8.33155 0.631135 8.69908L15.9572 16.7158C16.255 16.8716 16.6227 16.7514 16.771 16.4497C16.9026 16.1821 16.8168 15.8584 16.5699 15.691L6.09826 8.59048C5.54822 8.21752 5.50826 7.42172 6.01816 6.99553L12.7068 1.40499Z" fill="#FDFDFD"/>
                </svg>
            </span>
        </button>
        <button class="recipes-slider__button recipes-slider__button--next button-round-mask js-slide-next" aria-label="следующий слайд">
            <span class="button-round__icon">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.7068 1.40499C12.8356 1.29733 12.9101 1.13809 12.9101 0.970211C12.9101 0.536524 12.4428 0.263597 12.065 0.47667L0.603327 6.94199C-0.0873124 7.33157 -0.0714857 8.33155 0.631135 8.69908L15.9572 16.7158C16.255 16.8716 16.6227 16.7514 16.771 16.4497C16.9026 16.1821 16.8168 15.8584 16.5699 15.691L6.09826 8.59048C5.54822 8.21752 5.50826 7.42172 6.01816 6.99553L12.7068 1.40499Z" fill="#FDFDFD"/>
                </svg>
            </span>
        </button>
    `;
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>(SELECTOR)).forEach((el) => {
        const slider = map.get(el);

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
