import './YandexMap.scss';
import { LitElement, html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import loadScript from '../../../modules/load-script';
import { getOffsetTop, isElementInViewport } from '../../utils/dom';

type Placemark = {
    id: number;
    coords: [number, number];
    name: string;
    descr: string;
    address?: string;
    metroName?: string;
    metroInfo?: string;
    time?: string;
    status?: string;
    filters?: string;
};

export interface YandexMap {
    apiKey: string;
    centerLat: number;
    centerLng: number;
    instance: any;
    marker: string;
    zoom: number;
    isCardVisible: boolean;
    currentCoords: [number, number];
    marks: any[];
    activeTabs: number[];
    clusterer: any;
    activeMark: any;
    data?: {
        placemarks: Placemark[];
    };
    _animatedLayout: any;
    _isFetching: boolean;
    _error: Error | null;
    _observer: IntersectionObserver;
}

const header = document.querySelector<HTMLElement>('.js-header');

export class YandexMap extends LitElement {
    constructor() {
        super();

        this._closeCard = this._closeCard.bind(this);

        this.instance = null;
        this.zoom = 11;
        this._isFetching = false;
        this.currentCoords = [0, 0];
        this.data = this.getAttribute('map-data') ? JSON.parse(this.getAttribute('map-data')!) : null;
        this.marks = [];
        this.activeTabs = [];
        this.clusterer;
        this.isCardVisible = false;

        this._observer = new IntersectionObserver((entries, obs) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    obs.unobserve(entry.target);
                    this._init();
                }
            });
        });
    }

    static get properties() {
        return {
            centerLat: {
                type: Number,
                attribute: 'center-lat',
                reflect: true,
            },
            centerLng: {
                type: Number,
                attribute: 'center-lng',
                reflect: true,
            },
            apiKey: {
                type: String,
                attribute: 'api-key',
            },
            zoom: {
                type: Number,
                reflect: true,
            },
            marker: {
                type: String,
                reflect: true,
            },
            data: {
                type: Object,
            },
            _isFetching: {
                type: Boolean,
                attribute: false,
            },
            _error: {
                attribute: false,
            },
            isCardVisible: {
                type: Boolean,
            },
        };
    }

    createRenderRoot() {
        return this;
    }

    _init() {

        this._error = null;
        this._isFetching = true;
        loadScript(`https://api-maps.yandex.ru/2.1/?apikey=${this.apiKey}&lang=ru_RU`)
            .then(() => {
                const { ymaps } = window;
                ymaps.ready(() => {
                    const _this = this;
                    this.instance = new ymaps.Map(this.renderRoot.querySelector('.map'), {
                        center: [this.centerLat, this.centerLng],
                        zoom: this.zoom,
                        controls: ['zoomControl'],
                    });

                    const elements: any[] = [];

                    const animatedLayout = ymaps.templateLayoutFactory.createClass(
                        `<button class="map-placemark js-map-marker">
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="13" cy="13.0005" r="13" fill="#A3CD2D"/>
                            </svg>
                        </button>`,
                        {
                            build() {
                                animatedLayout.superclass.build.call(this);

                                const element = this.getParentElement().getElementsByClassName('map-placemark')[0];
                                elements.push(element);
                                // Если метка выбрана, то увеличим её размер.
                                const size = 48;
                                // Зададим фигуру активной области.
                                this.getData().options.set('shape', {
                                    type: 'Circle',
                                    coordinates: [size / 2, size / 2],
                                    radius: size / 2,
                                });
                                // Если метка выбрана, то зададим класс и запустим анимацию.
                                if (this.isActive) {
                                    element.classList.add('is-active');
                                } else if (this.inited) {
                                    element.classList.remove('is-active');
                                }

                                const closeAll = () => {
                                    document.body.classList.remove('no-scroll');
                                    _this.isCardVisible = false;
                                    if (_this.activeMark && _this.activeMark !== this) {
                                        _this.activeMark.isActive = false;
                                    }
                                    _this.renderRoot.querySelector('.card.is-active')?.classList.remove('is-active');
                                    elements.forEach((el) => {
                                        el.classList.remove('is-active');
                                    });
                                };

                                _this.addEventListener('overlay-click', closeAll);

                                if (!this.inited) {
                                    this.inited = true;
                                    this.isActive = false;
                                    // При клике по метке будем перестраивать макет.
                                    this.getData().geoObject.events.add(
                                        'click',
                                        function (this: any, event: any) {
                                            closeAll();

                                            this.isActive = !this.isActive;
                                            _this.isCardVisible = this.isActive;
                                            const mark = event.get('target');
                                            const index = _this.marks.indexOf(mark);

                                            if (this.isActive) {
                                                element.classList.add('is-active');

                                                if (index > -1) {
                                                    if (
                                                        matchMedia(
                                                            '(max-width: 767px), (max-width: 815px) and (orientation: landscape)',
                                                        ).matches
                                                    ) {
                                                        document.body.classList.add('no-scroll');
                                                    }

                                                    _this.renderRoot
                                                        .querySelectorAll('.card')
                                                        [index]?.classList.add('is-active');

                                                    if (!isElementInViewport(_this)) {
                                                        window.scrollTo({
                                                            top: getOffsetTop(_this) - (header?.offsetHeight || 0),
                                                            behavior: 'smooth',
                                                        });
                                                    }
                                                }
                                            }

                                            _this.activeMark = this;
                                        },
                                        this,
                                    );
                                    this.getData().geoObject.events.add(
                                        'mouseenter',
                                        function (this: any) {
                                            element.classList.add('is-hovered');
                                        },
                                        this,
                                    );
                                    this.getData().geoObject.events.add(
                                        'mouseleave',
                                        function (this: any) {
                                            element.classList.remove('is-hovered');
                                        },
                                        this,
                                    );
                                }
                            },
                        },
                    );
                    this._animatedLayout = animatedLayout;

                    if (this.data) {
                        if (this.data.placemarks?.[0]?.coords) {
                            this.currentCoords = this.data.placemarks[0].coords;
                        }

                        this.data.placemarks.forEach((placemark) => {
                            this._addMarker(...placemark.coords);
                        });

                        this.dispatchEvent(new Event('init'));

                        this.clusterer = new ymaps.Clusterer({
                            clusterIconLayout: ymaps.templateLayoutFactory.createClass('<div class="cluster-icon">{{ properties.geoObjects.length }}</div>'),
                            // Чтобы метка была кликабельной, переопределим ее активную область.
                            clusterIconShape: {
                                type: 'Rectangle',
                                coordinates: [[0, 0], [40, 40]],
                            },
                            hasBalloon: false,
                            // gridSize: 256
                        });

                        this.clusterer.add(this.marks);
                        this.instance.geoObjects.add(this.clusterer);
                    }

                    // Фильтрация маркеров
                    const container = document.querySelector<HTMLElement>('.js-map-container');
                    container?.addEventListener('setFilters', () => {
                        const filters = container.querySelectorAll<HTMLInputElement>('[data-map-filter]');

                        // смотрим какие чекбоксы выбраны
                        let activeCheckboxes: any[] = [];
                        filters.forEach(filter => {
                            if (filter.checked) {
                                activeCheckboxes.push(filter.dataset.mapFilter)
                            }
                        })

                        // Если выбран фильтр "Все линейки"
                        if (activeCheckboxes && activeCheckboxes[0] == 'all') {
                            this.data?.placemarks.forEach((placemark, i) => {
                                this.marks[i].options.set('visible', true);
                                this.clusterer.add(this.marks[i]);
                            })

                            return;
                        }

                        // если выбраны другие фильтры
                        this.data?.placemarks.forEach((placemark, i) => {
                            const valuesPlacemark = placemark.filters;
                            const valuesPlacemarkArr = valuesPlacemark?.split(', ');
                            let countCoincidence = 0;

                            // проверяем совпадения маркеров с фильтрами
                            if (valuesPlacemarkArr) {
                                for (let i = 0; i < valuesPlacemarkArr.length; i++) {
                                    for (let j = 0; j < activeCheckboxes.length; j++) {
                                        if (activeCheckboxes[j] == valuesPlacemarkArr[i]) {
                                            countCoincidence++;

                                        }
                                    }
                                }
                            }

                            if (countCoincidence == 0) {
                                // если маркер не совпадает с фильтрами
                                this.marks[i].options.set('visible', false);
                                this.clusterer.remove(this.marks[i]);
                            } else {
                                //  если маркер совпадает с фильтрами
								this.marks[i].options.set('visible', true);
                                this.clusterer.add(this.marks[i]);
                            }
                        })
                    })

                    this.instance.behaviors.disable('scrollZoom');

                    if (matchMedia('(pointer: coarse)').matches) {
                        this.instance.behaviors.disable('drag');
                    }

                    this.instance.events.add('click', this._closeCard);

                    window.addEventListener('resize', this._onResize);
                });
            })
            .catch((err) => {
                this._error = err;
                throw err;
            })
            .finally(() => {
                this._isFetching = false;
            });
    }

    connectedCallback() {
        super.connectedCallback();

        if (!this.apiKey) {
            throw new Error('API key not provided.');
        }

        this._observer.observe(this);
    }

    attributeChangedCallback(name: string, oldVal: string | null, newVal: string | null) {
        super.attributeChangedCallback(name, oldVal, newVal);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this._observer.disconnect();
        window.removeEventListener('resize', this._onResize);
        this._error = null;

        if (this.instance) {
            this.instance.destroy();
            this.instance = null;
        }
    }

    protected _onResize() {
        this.instance?.container.fitToViewport();
    }

    setActiveMarkById(id: number) {
        const placemark = this.data?.placemarks.find((placemark) => placemark.id === id);

        if (placemark) {
            const index = this.data?.placemarks.indexOf(placemark);

            if (typeof index === 'number') {
                const mark = this.marks[index];
                mark?.events.fire('click', {
                    coordPosition: mark.geometry.getCoordinates(),
                    target: mark,
                });
            }
        }
    }

    protected _addMarker(lat: number, lng: number) {
        const markOptions = {
            iconLayout: this._animatedLayout,
            hasBalloon: false,
        };
        const mark = new ymaps.Placemark([lat, lng], {}, markOptions);
        this.marks.push(mark);
        this.instance.geoObjects.add(mark);
    }

    protected _renderMap() {
        if (this._isFetching) {
            return html`<div class="map-loader">Загружаем карту...</div>`;
        }

        if (this._error) {
            return html`<div>${this._error.message}</div>`;
        }

        return '';
    }

    _closeCard() {
        this.dispatchEvent(new Event('overlay-click', { composed: true }));
    }

    render() {
        return html` <div class="map-container">
            <div class="map">${this._renderMap()}</div>
            ${this.data?.placemarks.map(
                (data) => html`
                    <div class="card map-card" data-filter-map-content="${data.filters}">
                        <div class="map-card__inner">
                            <div class="card__top">
                                ${data.name ? html`<div class="h3 card__name">${data.name}</div>` : ''}
                                ${data.descr ? html`<div class="card__descr">${data.descr}</div>` : ''}
                                <button
                                    class="card-close-btn"
                                    aria-label="Закрыть"
                                    @click="${this._closeCard}"
                                >
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13 1.00049L1 13.0005M13 13.0005L1 1.00049" stroke="#303030" stroke-width="2" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="card__bottom">
                                ${data.address
                                    ? html`
                                        <div class="card__address card__item">
                                            <div class="card__icon">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g>
                                                    <path d="M21 10.0005C21 17.0005 12 23.0005 12 23.0005C12 23.0005 3 17.0005 3 10.0005C3 7.61354 3.94821 5.32435 5.63604 3.63653C7.32387 1.9487 9.61305 1.00049 12 1.00049C14.3869 1.00049 16.6761 1.9487 18.364 3.63653C20.0518 5.32435 21 7.61354 21 10.0005Z" stroke="#303030" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M12 13.0005C13.6569 13.0005 15 11.6573 15 10.0005C15 8.34363 13.6569 7.00049 12 7.00049C10.3431 7.00049 9 8.34363 9 10.0005C9 11.6573 10.3431 13.0005 12 13.0005Z" stroke="#303030" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath>
                                                    <rect width="24" height="24" fill="white" transform="translate(0 0.000488281)"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                            <div class="card__label">${data.address}</div>
                                        </div>
                                    `
                                    : ''}
                                ${data.metroName
                                    ? html`
                                        <div class="card__item">
                                            <div class="card__icon">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.91 16.0005V8.86049H10.57L12.11 13.4805H12.39L13.93 8.86049H16.59V16.0005H14.84V11.4505H14.56L13.09 16.0005H11.41L9.94 11.4505H9.66V16.0005H7.91Z" fill="#303030"/>
                                                    <path d="M12 22.0005C17.5228 22.0005 22 17.5233 22 12.0005C22 6.47764 17.5228 2.00049 12 2.00049C6.47715 2.00049 2 6.47764 2 12.0005C2 17.5233 6.47715 22.0005 12 22.0005Z" stroke="#303030" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                            <div class="card__labels-block">
                                                <div class="card__label">${data.metroName}</div>
                                                ${data.metroInfo ? html`<div class="card__label">${data.metroInfo}</div>` : ''}
                                            </div>
                                        </div>
                                    `
                                    : ''}
                                ${data.time
                                    ? html`
                                        <div class="card__item">
                                            <div class="card__icon">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 22.0005C17.5228 22.0005 22 17.5233 22 12.0005C22 6.47764 17.5228 2.00049 12 2.00049C6.47715 2.00049 2 6.47764 2 12.0005C2 17.5233 6.47715 22.0005 12 22.0005Z" stroke="#303030" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M12 6.00049V12.0005L16 14.0005" stroke="#303030" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                            <div class="card__labels-block">
                                                <div class="card__label">${data.time}</div>
                                                ${data.status ? html`<div class="card__label">${data.status}</div>` : ''}
                                            </div>
                                        </div>
                                    `
                                    : ''}
                            </div>
                            <div class="card__message">
                                Мы не гарантируем наличие в точках продаж
                            </div>
                        </div>
                    </div>
                `,
            )}
        </div>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'app-yandex-map': YandexMap;
    }
}
