let innerText: string | null | undefined;

function onClick(this: HTMLElement, e: Event) {
    e.preventDefault();
    const container = this.closest('.js-marketplace-container');
    const items = container?.querySelectorAll('.js-marketplace-item');

    if (this.classList.contains('is-show')) {
        items?.forEach((item, i) => {
            if (i > 7) {
                item.classList.add('is-hide');
            }
        })
        if (innerText) this.innerHTML = innerText;
    } else {
        items?.forEach((item, i) => item.classList.remove('is-hide'));

        this.innerHTML = '<span>Свернуть</span>';
    }

    this.classList.toggle('is-show');
}

function init() {
    const items = document.querySelectorAll('.js-marketplace-item');
    const tabTrigger = document.querySelector<HTMLElement>('.js-marketplace-trigger');
    innerText = tabTrigger?.textContent;

    items.forEach((item, i) => {
        if (i > 7) {
            item.classList.add('is-hide');
        }
    })

    tabTrigger?.addEventListener('click', onClick);
}

const _module = { init };

export default _module;
