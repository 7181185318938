const SELECTOR = '.js-toggle-class-trigger';

function onClick(this: HTMLAnchorElement, event: any) {
    const container = this.closest('.js-toggle-class-container');
    const items = container?.querySelectorAll('.js-toggle-class-item');

    items?.forEach(item => item.classList.toggle('is-active'));
}

function init() {
    const container = document.querySelector('.js-toggle-class-container');
    const items = container?.querySelectorAll('.js-toggle-class-item');
    const backButton = container?.querySelector<HTMLAnchorElement>(SELECTOR);

    if (items && items.length > 1) {
        backButton?.addEventListener('click', onClick);
    } else {
        backButton?.classList.add('is-hide');
    }
}

function destroy() {
    const backButtons = Array.from(document.querySelectorAll<HTMLAnchorElement>(SELECTOR));
    backButtons.forEach((btn) => {
        btn.removeEventListener('click', onClick);
    });
}

const _module = { init, destroy };

export default _module;
