/* eslint-disable max-len, max-lines */
import './WebShare.scss';
import { LitElement, html, css, unsafeCSS, TemplateResult } from 'lit';
import { ok, mail, viber, whatsapp, telegram, tw, vk } from 'vanilla-sharing';
import _styles from './WebShare.ce.scss';
import { isMobileDevice } from '../../utils/mobile';
import loadScript from '../../../modules/load-script';

export interface WebShare {
    /**
     * Шерить ли в Facebook
     */
    facebook: boolean;

    /**
     * App ID для Facebook (получается в настройках бизнес-аккаунта FB)
     */
    facebookAppId: string | null;

    /**
     * Показывает, загружен ли Facebook SDK
     */
    facebookReady: boolean;

    /**
     * Шерить ли в Mail.ru
     */
    mail: boolean;

    /**
     * Использовать ли Web Share API (если доступно)
     */
    native: boolean;

    /**
     * Шерить ли в Одноклассники
     */
    ok: boolean;

    /**
     * Шерить ли в Telegram
     */
    telegram: boolean;

    /**
     * Заголовок для шеринга
     */
    title: string;

    /**
     * Шерить ли в Twitter
     */
    twitter: boolean;

    /**
     * Шерить ли в Skype
     */
    skype: boolean;

    /**
     * Показывает, загружен ли Skype SDK
     */
    skypeReady: boolean;

    /**
     * URL, который шерим
     */
    url: string;

    /**
     * Шерить ли в Viber
     */
    viber: boolean;

    /**
     * Шерить ли в VK
     */
    vk: boolean;

    /**
     * Шерить ли в Whatsapp
     */
    whatsapp: boolean;

    /**
     * Вычисленный boolean,
     * показывающий реальную возможность использовать WebShare API
     */
    _useNative: boolean;
}

function shareFacebook(url: string) {
    if ((window as any).FB) {
        (window as any).FB.ui({
            method: 'share',
            href: url,
        });
    }
}

function shareVK(url: string) {
    vk({
        url,
        title: document.title,
    });
}

function shareTwitter(url: string) {
    tw({
        url,
        title: document.title,
    });
}

function shareOK(url: string) {
    ok({
        url,
        title: document.title,
    });
}

function shareMail(url: string) {
    mail({
        url,
        title: document.title,
    });
}

function shareViber(url: string) {
    viber({
        url,
        title: document.title,
    });
}

function shareWhatsapp(url: string) {
    whatsapp({
        url,
        title: document.title,
    });
}

function shareTelegram(url: string) {
    telegram({
        url,
        title: document.title,
    });
}

function shareSkype(url: string) {
    telegram({
        url,
        title: document.title,
    });
}

function loadSkypeSharingSDK(): Promise<void> {
    return new Promise((resolve) => {
        ((r, d, s) => {
            (r as any).loadSkypeWebSdkAsync =
                (r as any).loadSkypeWebSdkAsync ||
                function fn(p: any) {
                    const sjs = d.getElementsByTagName(s)[0] as any;
                    if (d.getElementById(p.id)) {
                        return;
                    }
                    const js = d.createElement(s) as HTMLScriptElement;
                    js.id = p.id;
                    js.src = p.scriptToLoad;
                    js.onload = p.callback;
                    sjs.parentNode.insertBefore(js, sjs);
                };
            const p = {
                scriptToLoad: 'https://swx.cdn.skype.com/shared/v/latest/skypewebsdk.js',
                id: 'skype_web_sdk',
                callback: resolve,
            };
            (r as any).loadSkypeWebSdkAsync(p);
        })(window, document, 'script');
    });
}

async function loadFacebookSharingSDK(fbAppId?: string | null) {
    if (!fbAppId) {
        console.error('Invalid Facebook App ID');
    }

    await loadScript(`https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0&appId=${fbAppId}`);
}

export class WebShare extends LitElement {
    constructor() {
        super();
        this.share = this.share.bind(this);
        this._closeOnOutsideClick = this._closeOnOutsideClick.bind(this);

        this._useNative = false;
        this.native = false;

        this.title = document.title;
        this.url = window.location.href;

        this.facebookReady = false;
        this.skypeReady = false;

        this.facebook = false;
        this.mail = false;
        this.ok = false;
        this.telegram = false;
        this.twitter = false;
        this.skype = false;
        this.viber = false;
        this.vk = false;
        this.whatsapp = false;

        this.facebookAppId = this.getAttribute('facebook-app-id');
    }

    static get styles() {
        return css`
            ${unsafeCSS(_styles)}
        `;
    }

    static get properties() {
        return {
            facebook: {
                type: Boolean,
            },
            facebookAppId: {
                type: String,
            },
            mail: {
                type: Boolean,
            },
            native: {
                type: Boolean,
            },
            ok: {
                type: Boolean,
            },
            telegram: {
                type: Boolean,
            },
            twitter: {
                type: Boolean,
            },
            skype: {
                type: Boolean,
            },
            viber: {
                type: Boolean,
            },
            vk: {
                type: Boolean,
            },
            whatsapp: {
                type: Boolean,
            },
            _useNative: {
                type: Boolean,
            },
        };
    }

    connectedCallback() {        super.connectedCallback();
        this._useNative = this.native && 'share' in navigator && isMobileDevice();

        if (!this._useNative) {
            if (this.facebook) {
                loadFacebookSharingSDK(this.facebookAppId).then(() => {
                    this.facebookReady = true;
                });
            }

            if (this.skype) {
                loadSkypeSharingSDK().then(() => {
                    this.skypeReady = true;
                });
            }
        }

        document.addEventListener('click', this._closeOnOutsideClick);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        document.removeEventListener('click', this._closeOnOutsideClick);
    }

    async share() {
        if (this._useNative) {
            await navigator.share({ text: '', title: this.title, url: this.url });
        } else {
            this.renderRoot.querySelector('.list')?.classList.toggle('is-open');
        }
    }

    private _closeOnOutsideClick(event: any) {
        const el = event.target.closest('app-web-share');

        if (!el) {
            this.renderRoot.querySelector('.list')?.classList.remove('is-open');
        }
    }

    /**
     *
     * Lit-шаблон для элемента списка
     *
     * @param iconHtml Lit-шаблон SVG-иконки
     * @param label Название соц. сети
     * @param sharingFn Релевантная функция шеринга
     * @returns TemplateResult<1>
     */
    li(iconHtml: TemplateResult<1>, label: string, sharingFn: any) {
        return html`
            <li class="list__item">
                <button
                    type="button"
                    class="list-btn list-btn--${label}"
                    aria-label="Поделиться в ${label}"
                    @click="${() => sharingFn(this.url)}"
                >
                    ${iconHtml}
                </button>
            </li>
        `;
    }

    render() {
        return html`
            <button
                type="button"
                class="list-btn ${this._useNative ? '' : 'list-btn--main'}"
                aria-label="Поделиться"
                @click="${this.share}"
            >
                <span class="list-btn__bg">
                    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="150" height="150">
                    <circle cx="75" cy="75" r="75" fill="#303030"/>
                    </mask>
                    <g>
                    <circle cx="75" cy="75" r="75" fill="#303030"/>
                    <g style="mix-blend-mode:color-dodge">
                    <path d="M183.293 63.898C183.788 63.7337 184.293 63.5694 184.789 63.4052C184.862 63.5522 184.936 63.6991 185 63.8461C184.513 64.0363 184.018 64.2179 183.531 64.4081C183.449 64.2352 183.375 64.0709 183.293 63.898Z" fill="#F5F5F5"/>
                    <path d="M184.073 67.6071L183.944 67.3305L184.211 67.3391L184.073 67.6071Z" fill="#F5F5F5"/>
                    <path d="M139.873 67.5553C140.167 67.3564 140.451 67.1662 140.745 66.976L140.773 66.9985C140.891 67.0936 141.001 67.1824 141.103 67.2786C140.91 67.5464 140.708 67.8148 140.506 68.0827C140.295 67.9098 140.084 67.7282 139.873 67.5553Z" fill="#F5F5F5"/>
                    <path d="M136.44 70.6073C136.844 70.3306 137.238 70.054 137.633 69.7773C137.78 69.9329 137.927 70.0972 138.064 70.2528C137.679 70.5381 137.284 70.8148 136.889 71.1001C136.743 70.9359 136.587 70.7716 136.44 70.6073Z" fill="#F5F5F5"/>
                    <path d="M137.817 37.9081C137.927 38.6084 137.954 38.79 137.982 38.9716C137.789 38.9543 137.596 38.9369 137.404 38.911C137.477 38.7208 137.56 38.5306 137.817 37.9081Z" fill="#F5F5F5"/>
                    <path d="M139.478 43.8652C139.717 44.6952 139.891 45.2831 140.194 46.3034C138.496 45.776 137.459 45.4647 135.549 44.8768C137.275 44.4358 138.248 44.1851 139.478 43.8652Z" fill="#F5F5F5"/>
                    <path d="M128.866 53.9637C129.325 53.86 129.757 53.6265 130.005 53.7303C131.143 54.2145 132.226 54.7937 134.044 55.6929C132.41 55.7967 131.584 55.8486 130.739 55.9004C130.106 55.2433 129.527 54.6467 128.866 53.9637Z" fill="#F5F5F5"/>
                    <path d="M157.892 27.6366C157.654 27.8009 157.424 27.9651 157.195 28.1294C156.993 27.7663 156.791 27.4118 156.589 27.066C156.773 26.9622 156.956 26.8671 157.149 26.7634C157.397 27.0573 157.645 27.3427 157.892 27.6366Z" fill="#F5F5F5"/>
                    <path d="M174.976 70.0366L174.499 70.1231L174.496 70.1144C174.424 69.832 174.351 69.5496 174.287 69.2672L174.765 69.1807C174.838 69.466 174.902 69.7513 174.976 70.0366Z" fill="#F5F5F5"/>
                    <path d="M180.796 45.5598C180.722 45.5858 180.695 45.603 180.658 45.6203C180.695 45.603 180.75 45.5858 180.796 45.5598Z" fill="#F5F5F5"/>
                    <path d="M119.788 42.1878H121.266C121.229 42.3514 121.193 42.5236 121.156 42.6958L121.156 42.698C120.889 42.5942 120.614 42.4905 119.788 42.1878Z" fill="#F5F5F5"/>
                    <path d="M159.664 41.7901C159.352 41.8247 159.031 41.868 158.709 41.9026C158.682 41.4357 158.654 40.9688 158.636 40.5192C158.81 40.4846 158.976 40.4586 159.15 40.4241C159.324 40.8823 159.49 41.3319 159.664 41.7901Z" fill="#F5F5F5"/>
                    <path d="M130.831 39.473C130.813 39.7324 130.803 39.9918 130.785 40.2512L130.556 40.2425V39.4644C130.647 39.473 130.739 39.473 130.831 39.473Z" fill="#F5F5F5"/>
                    <path d="M157.553 58.0619C157.534 58.1484 157.516 58.2435 157.498 58.33C157.323 58.2695 157.14 58.2089 156.975 58.1484C157.167 58.1139 157.359 58.088 157.551 58.0621L157.553 58.0619Z" fill="#F5F5F5"/>
                    <path d="M176.004 58.7104C175.986 58.9265 175.967 59.134 175.949 59.3415C175.545 59.2551 175.15 59.16 174.755 59.0649C174.774 58.9438 174.792 58.8314 174.811 58.7104H176.004Z" fill="#F5F5F5"/>
                    <path d="M176.252 47.3063L176.316 47.5225L176.132 47.4965L176.252 47.3063Z" fill="#F5F5F5"/>
                    <path d="M156.121 32.1153C156.011 32.1325 155.873 32.1758 155.781 32.1412C155.699 32.1066 155.68 31.9769 155.634 31.8905L155.91 31.7435C155.974 31.8645 156.047 31.9942 156.121 32.1153Z" fill="#F5F5F5"/>
                    <path d="M165.778 27.5156C165.374 29.1929 164.493 29.8327 162.262 29.0546C162.838 27.8525 163.442 27.1646 164.41 27.1665V24.6019C164.539 24.5586 164.658 24.5241 164.786 24.4895V27.1992C165.083 27.2481 165.411 27.3523 165.778 27.5156Z" fill="#F5F5F5"/>
                    <path d="M148.428 63.777L147.822 66.2151C147.575 66.146 147.327 66.0768 147.079 65.9903C147.327 65.1949 147.575 64.3908 147.822 63.5954L148.428 63.777Z" fill="#F5F5F5"/>
                    <path d="M123.249 65.6877C123.249 65.6012 123.294 65.5061 123.322 65.4197C123.386 65.4456 123.496 65.4802 123.506 65.5062C123.506 65.5926 123.46 65.6877 123.432 65.7742C123.368 65.7482 123.249 65.7136 123.249 65.6877Z" fill="#F5F5F5"/>
                    <path d="M128.967 76.9708C128.949 76.9103 129.087 76.7892 129.39 76.3742C129.316 76.9785 129.28 77.2208 129.243 77.4625L129.243 77.4636C129.142 77.2993 129.022 77.1437 128.967 76.9708Z" fill="#F5F5F5"/>
                    <path d="M123.597 55.9523C123.689 56.4884 123.79 57.0331 123.882 57.5691L123.166 57.6729L123.001 56.0388C123.203 56.0128 123.395 55.9783 123.597 55.9523Z" fill="#F5F5F5"/>
                    <path d="M121.82 62.4877L121.826 62.4282C121.899 62.4368 121.963 62.4368 122.018 62.4455V63.2582C121.927 63.2495 121.826 63.2495 121.734 63.2409C121.768 62.9927 121.794 62.737 121.82 62.4877Z" fill="#F5F5F5"/>
                    <path d="M164.887 45.214L164.116 45.1189C164.208 44.4531 164.291 43.7874 164.383 43.1303C164.621 43.1562 164.86 43.1908 165.098 43.2167C165.025 43.8825 164.961 44.5482 164.887 45.214Z" fill="#F5F5F5"/>
                    <path d="M165.007 38.5998C164.704 38.228 164.502 37.9686 164.135 37.5104C164.685 37.3721 165.07 37.2857 166.033 37.0699L166.035 37.0694C165.512 37.8476 165.291 38.1761 165.007 38.5998Z" fill="#F5F5F5"/>
                    <path d="M166.264 41.9285C166.347 41.9285 166.42 41.9371 166.503 41.9371C166.485 42.1879 166.466 42.4386 166.457 42.698C166.393 42.6893 166.328 42.6893 166.264 42.6893V41.9285Z" fill="#F5F5F5"/>
                    <path d="M171.267 44.2456C171.368 44.237 171.543 44.2975 171.561 44.358C171.589 44.4445 171.506 44.5742 171.469 44.6866C171.368 44.626 171.231 44.5914 171.176 44.505C171.139 44.4618 171.221 44.2456 171.267 44.2456Z" fill="#F5F5F5"/>
                    <path d="M164.557 21.204C164.3 21.1175 164.034 21.0397 163.777 20.9619L163.96 20.3653C164.217 20.5123 164.484 20.6593 164.741 20.8063C164.676 20.9446 164.621 21.0743 164.557 21.204Z" fill="#F5F5F5"/>
                    <path d="M183.779 69.2153C183.679 69.4292 183.57 69.6431 183.461 69.857L183.458 69.8637L181.668 69.3709C181.721 69.0761 181.775 68.7896 181.829 68.5032L181.833 68.4804C182.118 68.3161 182.402 68.1605 182.843 67.9097C183.173 68.3678 183.476 68.7918 183.779 69.2153Z" fill="#F5F5F5"/>
                    <path d="M176.215 67.7368C176.353 67.2181 176.463 66.8203 176.591 66.3448C175.279 66.6128 175.279 66.613 176.215 67.7368Z" fill="#F5F5F5"/>
                    <path d="M118.191 26.5645C118.429 26.6942 118.677 26.8152 118.916 26.9449L118.915 26.9459C118.87 27.0493 118.824 27.1527 118.787 27.2475C118.53 27.1524 118.273 27.066 118.016 26.9709C118.071 26.8325 118.135 26.7028 118.191 26.5645Z" fill="#F5F5F5"/>
                    <path d="M115.767 22.7516C116.134 22.5268 116.345 22.2847 116.501 22.3193C116.658 22.3452 116.749 22.6565 116.869 22.8467C116.557 22.8208 116.244 22.7948 115.767 22.7516Z" fill="#F5F5F5"/>
                    <path d="M150.457 26.4262C150.686 28.2591 149.961 29.1842 148.566 29.3485C148.318 29.0805 148.061 28.8125 147.804 28.5531C148.685 27.8441 149.576 27.1351 150.457 26.4262Z" fill="#F5F5F5"/>
                    <path d="M138.753 31.4495C138.918 31.5533 139.074 31.6657 139.239 31.7694C139.102 32.0115 138.992 32.2622 138.817 32.4611C138.781 32.4957 138.496 32.2882 138.322 32.1931C138.468 31.9423 138.606 31.7003 138.753 31.4495Z" fill="#F5F5F5"/>
                    <path d="M114.666 51.5601C114.647 51.6466 114.62 51.7331 114.592 51.8195L114.28 51.7503C114.408 51.6898 114.537 51.6206 114.666 51.5601Z" fill="#F5F5F5"/>
                    <path d="M108.455 74.9484C108.845 75.2948 109.24 75.6491 109.631 75.9955C109.54 76.1325 109.449 76.2694 109.354 76.3985C108.945 76.073 108.54 75.7353 108.132 75.4098C108.24 75.2519 108.346 75.1063 108.455 74.9484Z" fill="#F5F5F5"/>
                    <path d="M105.627 77.4775L105.803 77.228L105.929 77.4625L105.627 77.4775Z" fill="#F5F5F5"/>
                    <path d="M83.5721 39.241C83.8915 39.3955 84.1988 39.5465 84.5107 39.7053L84.5051 39.7407C84.4816 39.8902 84.4596 40.0297 84.4272 40.1661C84.0985 40.1335 83.7646 40.0929 83.4313 40.0524C83.4757 39.7834 83.5277 39.51 83.5721 39.241Z" fill="#F5F5F5"/>
                    <path d="M79.2077 37.7991C79.6497 38.0099 80.0871 38.2129 80.5245 38.4158C80.4629 38.6206 80.3938 38.8296 80.3276 39.0265C79.8873 38.8359 79.4499 38.6329 79.005 38.4344C79.0741 38.2253 79.1386 38.0082 79.2077 37.7991Z" fill="#F5F5F5"/>
                    <path d="M108.265 22.6399C107.712 23.0852 107.569 23.1999 107.425 23.3145C107.343 23.1392 107.262 22.9639 107.188 22.7842C107.39 22.7526 107.596 22.7289 108.265 22.6399Z" fill="#F5F5F5"/>
                    <path d="M103.927 27.0548C103.327 27.6762 102.904 28.1209 102.17 28.8929C101.779 27.1611 101.53 26.1087 101.085 24.1641C102.331 25.4355 103.035 26.1514 103.927 27.0548Z" fill="#F5F5F5"/>
                    <path d="M89.8604 22.9303C90.1799 23.2752 90.5982 23.5315 90.6321 23.7976C90.7812 25.0238 90.8202 26.2498 90.9489 28.2707C90.0419 26.91 89.5838 26.2218 89.1165 25.5176C89.3699 24.6415 89.5983 23.8432 89.8604 22.9303Z" fill="#F5F5F5"/>
                    <path d="M127.214 34.8595C126.952 34.7354 126.695 34.6191 126.437 34.5028C126.652 34.1467 126.858 33.7949 127.057 33.4474C127.239 33.5542 127.413 33.6654 127.6 33.7802C127.469 34.1414 127.345 34.4983 127.214 34.8595Z" fill="#F5F5F5"/>
                    <path d="M98.9708 70.8279L98.6572 70.4586L98.6636 70.4523C98.8723 70.2482 99.0809 70.0442 99.2942 69.848L99.6079 70.2174C99.397 70.4236 99.1816 70.6218 98.9708 70.8279Z" fill="#F5F5F5"/>
                    <path d="M123.116 63.6208C123.057 63.5703 123.028 63.5551 122.995 63.532C123.028 63.5551 123.071 63.5941 123.116 63.6208Z" fill="#F5F5F5"/>
                    <path d="M95.5374 9.19397L96.2764 10.4716C96.1162 10.5218 95.9485 10.5763 95.7809 10.6307L95.7787 10.6314C95.7356 10.3494 95.6879 10.0595 95.5374 9.19397Z" fill="#F5F5F5"/>
                    <path d="M115.821 43.4679C115.635 43.2154 115.436 42.9593 115.246 42.6988C115.637 42.4415 116.028 42.1843 116.409 41.9436C116.526 42.0771 116.632 42.2069 116.749 42.3404C116.438 42.7203 116.131 43.088 115.821 43.4679Z" fill="#F5F5F5"/>
                    <path d="M103.414 17.3832C103.18 17.4971 102.95 17.6189 102.716 17.7327L102.609 17.53L103.284 17.1409C103.322 17.2246 103.368 17.3039 103.414 17.3832Z" fill="#F5F5F5"/>
                    <path d="M100.648 49.7786C100.564 49.8059 100.472 49.8377 100.388 49.865C100.353 49.684 100.314 49.4949 100.284 49.3218C100.41 49.4709 100.529 49.6242 100.647 49.7775L100.648 49.7786Z" fill="#F5F5F5"/>
                    <path d="M109.311 66.0536C109.114 66.1458 108.925 66.2337 108.736 66.3216C108.609 65.9292 108.494 65.5403 108.379 65.1516C108.494 65.1069 108.6 65.0666 108.714 65.022L109.311 66.0536Z" fill="#F5F5F5"/>
                    <path d="M119.329 60.5658L119.173 60.7295L119.104 60.5577L119.329 60.5658Z" fill="#F5F5F5"/>
                    <path d="M122.443 35.5673C122.372 35.4807 122.266 35.3833 122.25 35.2867C122.239 35.198 122.342 35.1172 122.394 35.0343L122.66 35.1989C122.587 35.315 122.511 35.4433 122.443 35.5673Z" fill="#F5F5F5"/>
                    <path d="M131.262 41.6159C129.604 42.1054 128.609 41.6634 128.168 39.3459C129.499 39.2428 130.398 39.421 130.88 40.2588L133.105 38.9765C133.207 39.066 133.297 39.1519 133.391 39.2457L131.04 40.6006C131.146 40.8813 131.22 41.217 131.262 41.6159Z" fill="#F5F5F5"/>
                    <path d="M91.1278 44.748L88.7096 45.4433C88.6456 45.1945 88.5817 44.9457 88.5328 44.6882C89.3468 44.5047 90.1683 44.3169 90.9823 44.1335L91.1278 44.748Z" fill="#F5F5F5"/>
                    <path d="M76.8802 23.9357C76.9552 23.8924 77.0607 23.8846 77.1494 23.8652C77.1591 23.9337 77.1841 24.0462 77.1662 24.0671C77.0912 24.1104 76.9858 24.1183 76.897 24.1377C76.8874 24.0692 76.8577 23.9486 76.8802 23.9357Z" fill="#F5F5F5"/>
                    <path d="M69.9508 34.5212C69.9942 34.475 70.1681 34.5336 70.6796 34.588C70.1187 34.8267 69.8901 34.9161 69.662 35.0053L69.661 35.0057C69.753 34.8362 69.8283 34.6552 69.9508 34.5212Z" fill="#F5F5F5"/>
                    <path d="M85.5006 19.3696C85.0815 19.7169 84.6594 20.0766 84.2403 20.424L83.7922 19.8569L85.1273 18.8969C85.2508 19.0585 85.3771 19.2079 85.5006 19.3696Z" fill="#F5F5F5"/>
                    <path d="M78.9419 21.1004L78.9966 21.0759C79.0258 21.1438 79.058 21.1993 79.078 21.2512L78.3729 21.6576C78.3345 21.5739 78.284 21.4865 78.2456 21.4029C78.4779 21.3081 78.7128 21.203 78.9419 21.1004Z" fill="#F5F5F5"/>
                    <path d="M115.462 49.6952L115.159 48.9811C115.782 48.7276 116.401 48.4662 117.017 48.217C117.114 48.4363 117.203 48.6598 117.3 48.8791C116.686 49.1485 116.076 49.4258 115.462 49.6952Z" fill="#F5F5F5"/>
                    <path d="M121.26 46.4913C121.431 46.0435 121.555 45.7393 121.769 45.1927C122.164 45.5994 122.432 45.8893 123.1 46.6134L123.101 46.6149C122.165 46.5517 121.77 46.5255 121.26 46.4913Z" fill="#F5F5F5"/>
                    <path d="M119.001 49.2428C119.042 49.3143 119.071 49.3821 119.113 49.4535C118.886 49.563 118.659 49.6725 118.43 49.7943C118.405 49.7345 118.373 49.6787 118.341 49.6233L119.001 49.2428Z" fill="#F5F5F5"/>
                    <path d="M119.492 54.7264C119.55 54.8094 119.585 54.9904 119.541 55.0365C119.48 55.1036 119.326 55.097 119.21 55.1215C119.212 55.0039 119.174 54.8676 119.221 54.7768C119.24 54.7234 119.469 54.6867 119.492 54.7264Z" fill="#F5F5F5"/>
                    <path d="M136.127 37.4046C136.073 37.1391 136.008 36.8701 135.947 36.609L136.556 36.4694C136.557 36.7651 136.563 37.0688 136.564 37.3645C136.412 37.3781 136.271 37.3953 136.127 37.4046Z" fill="#F5F5F5"/>
                    <path d="M104.085 78.0277C103.85 78.0482 103.609 78.0609 103.369 78.0736L103.362 78.074L102.894 76.2802C103.177 76.1792 103.452 76.0823 103.728 75.9855L103.75 75.9778C104.034 76.1417 104.312 76.3099 104.75 76.5654C104.517 77.08 104.301 77.5541 104.085 78.0277Z" fill="#F5F5F5"/>
                    <path d="M101.586 70.7494C102.105 70.609 102.505 70.5053 102.982 70.3787C102.093 69.3779 102.093 69.3782 101.586 70.7494Z" fill="#F5F5F5"/>
                    <path d="M108.293 0.00148726C108.3 0.272661 108.319 0.547486 108.326 0.81866L108.324 0.818777C108.212 0.830935 108.099 0.84309 107.999 0.85885C107.953 0.589097 107.899 0.323692 107.853 0.0539389C108.001 0.0323849 108.145 0.0230408 108.293 0.00148726Z" fill="#F5F5F5"/>
                    <path d="M110.389 -4C110.768 -3.79497 111.083 -3.73345 111.131 -3.58125C111.187 -3.43337 110.963 -3.19836 110.857 -3.00009C110.724 -3.28287 110.59 -3.56573 110.389 -4Z" fill="#F5F5F5"/>
                    <path d="M124.546 27.8264C123.071 28.9413 121.906 28.777 121.065 27.6529C121.174 27.3046 121.278 26.9483 121.375 26.5964C122.43 27.0038 123.491 27.4191 124.546 27.8264Z" fill="#F5F5F5"/>
                    <path d="M114.336 20.22C114.329 20.4148 114.309 20.6059 114.302 20.8006C114.023 20.8026 113.75 20.8328 113.491 20.7814C113.442 20.767 113.48 20.4172 113.475 20.2188C113.766 20.2204 114.045 20.2184 114.336 20.22Z" fill="#F5F5F5"/>
                    <path d="M84.8452 9.45201C84.7611 9.47937 84.6723 9.49884 84.5835 9.51827L84.4874 9.21378C84.6042 9.29462 84.7285 9.37117 84.8452 9.45201Z" fill="#F5F5F5"/>
                    </g>
                    <g style="mix-blend-mode:color-dodge">
                    <path d="M55.589 173.992C56.0536 174.23 56.5247 174.474 56.9893 174.712C56.9361 174.868 56.8829 175.024 56.8233 175.173C56.3466 174.959 55.8697 174.733 55.3931 174.52C55.4583 174.34 55.5238 174.172 55.589 173.992Z" fill="#F5F5F5"/>
                    <path d="M53.4942 177.151L53.6012 176.866L53.7817 177.061L53.4942 177.151Z" fill="#F5F5F5"/>
                    <path d="M22.5365 145.604C22.8841 145.673 23.2192 145.743 23.5607 145.819L23.5642 145.854C23.5791 146.005 23.5931 146.146 23.5962 146.286C23.2703 146.337 22.9373 146.381 22.605 146.425C22.5801 146.153 22.5614 145.875 22.5365 145.604Z" fill="#F5F5F5"/>
                    <path d="M17.9551 145.298C18.4354 145.392 18.9093 145.479 19.3832 145.566C19.3753 145.78 19.3613 146 19.347 146.208C18.8734 146.133 18.3995 146.046 17.9195 145.965C17.9335 145.745 17.9411 145.518 17.9551 145.298Z" fill="#F5F5F5"/>
                    <path d="M42.2116 123.328C41.79 123.898 41.68 124.045 41.57 124.193C41.4471 124.043 41.3243 123.893 41.2076 123.738C41.3945 123.657 41.5879 123.582 42.2116 123.328Z" fill="#F5F5F5"/>
                    <path d="M39.1336 128.694C38.7098 129.447 38.4133 129.984 37.8991 130.916C37.0839 129.335 36.5781 128.377 35.658 126.603C37.1822 127.524 38.0432 128.042 39.1336 128.694Z" fill="#F5F5F5"/>
                    <path d="M24.4993 128.217C24.8951 128.471 25.3639 128.615 25.4638 128.865C25.9171 130.016 26.2641 131.195 26.8982 133.122C25.6785 132.03 25.0622 131.477 24.433 130.911C24.4569 129.999 24.4763 129.167 24.4993 128.217Z" fill="#F5F5F5"/>
                    <path d="M63.6056 130.431C63.3213 130.377 63.0433 130.328 62.7654 130.28C62.8824 129.881 62.9934 129.488 63.0981 129.102C63.3007 129.16 63.4972 129.224 63.7063 129.288C63.6707 129.671 63.6412 130.048 63.6056 130.431Z" fill="#F5F5F5"/>
                    <path d="M45.3846 172.371L44.9883 172.092L44.9929 172.084C45.1431 171.834 45.2933 171.584 45.4499 171.34L45.8462 171.62C45.6945 171.872 45.5363 172.118 45.3846 172.371Z" fill="#F5F5F5"/>
                    <path d="M66.9 159.34C66.83 159.306 66.7984 159.298 66.7603 159.284C66.7984 159.298 66.8494 159.326 66.9 159.34Z" fill="#F5F5F5"/>
                    <path d="M26.5207 113.479L27.5571 114.533C27.4149 114.621 27.2667 114.716 27.1184 114.811L27.1165 114.812C27.0037 114.549 26.8845 114.28 26.5207 113.479Z" fill="#F5F5F5"/>
                    <path d="M54.7668 141.629C54.5233 141.431 54.2672 141.232 54.0173 141.027C54.3305 140.68 54.6438 140.332 54.9511 140.004C55.0981 140.104 55.2324 140.203 55.3793 140.303C55.1753 140.749 54.9709 141.183 54.7668 141.629Z" fill="#F5F5F5"/>
                    <path d="M36.1983 119.446C36.0006 119.615 35.8095 119.791 35.6119 119.96L35.4571 119.79L36.0114 119.244C36.0696 119.316 36.1339 119.381 36.1983 119.446Z" fill="#F5F5F5"/>
                    <path d="M41.6962 151.545C41.6218 151.592 41.5412 151.646 41.4667 151.694C41.3875 151.527 41.3019 151.353 41.2291 151.193C41.3886 151.306 41.5419 151.425 41.6952 151.544L41.6962 151.545Z" fill="#F5F5F5"/>
                    <path d="M54.1729 165.154C54.0061 165.293 53.8455 165.425 53.6848 165.558C53.4632 165.209 53.2541 164.861 53.045 164.513C53.1441 164.441 53.2371 164.375 53.3362 164.303L54.1729 165.154Z" fill="#F5F5F5"/>
                    <path d="M62.4696 157.326L62.3607 157.524L62.2504 157.375L62.4696 157.326Z" fill="#F5F5F5"/>
                    <path d="M59.1733 132.312C59.0837 132.245 58.9564 132.178 58.9166 132.088C58.8833 132.005 58.9628 131.901 58.9922 131.807L59.2901 131.9C59.2489 132.031 59.208 132.175 59.1733 132.312Z" fill="#F5F5F5"/>
                    <path d="M69.2214 135.968C67.7434 136.857 66.6697 136.678 65.6598 134.542C66.9199 134.109 67.8333 134.056 68.5108 134.748L70.3376 132.948C70.4585 133.009 70.5668 133.07 70.6816 133.137L68.7515 135.039C68.9246 135.285 69.0804 135.592 69.2214 135.968Z" fill="#F5F5F5"/>
                    <path d="M31.2271 149.051L29.0656 150.33C28.9411 150.105 28.8166 149.88 28.7043 149.643C29.4447 149.261 30.1912 148.873 30.9316 148.492L31.2271 149.051Z" fill="#F5F5F5"/>
                    <path d="M12.2091 132.441C12.2707 132.38 12.3707 132.346 12.4516 132.305C12.4781 132.369 12.5307 132.472 12.5187 132.497C12.4571 132.557 12.3572 132.591 12.2763 132.632C12.2497 132.568 12.1906 132.459 12.2091 132.441Z" fill="#F5F5F5"/>
                    <path d="M8.18274 144.437C8.21297 144.382 8.39582 144.395 8.90385 144.32C8.422 144.692 8.22366 144.836 8.02578 144.979L8.02487 144.98C8.07107 144.792 8.09819 144.598 8.18274 144.437Z" fill="#F5F5F5"/>
                    <path d="M19.388 125.856C19.0706 126.298 18.7534 126.752 18.436 127.194L17.86 126.756L18.908 125.491C19.0681 125.617 19.2279 125.73 19.388 125.856Z" fill="#F5F5F5"/>
                    <path d="M13.4864 129.176L13.5331 129.139C13.5784 129.197 13.6235 129.243 13.6559 129.288L13.077 129.859C13.0188 129.787 12.948 129.715 12.8898 129.643C13.0904 129.493 13.2909 129.333 13.4864 129.176Z" fill="#F5F5F5"/>
                    <path d="M55.9908 147.756L55.5178 147.139C56.0564 146.737 56.5886 146.329 57.121 145.933C57.2699 146.122 57.4125 146.316 57.5614 146.504C57.0357 146.919 56.5165 147.341 55.9908 147.756Z" fill="#F5F5F5"/>
                    <path d="M60.7856 143.198C60.838 142.721 60.8812 142.395 60.9501 141.812C61.4345 142.107 61.7663 142.321 62.5949 142.856L62.5966 142.857C61.6754 143.03 61.2869 143.104 60.7856 143.198Z" fill="#F5F5F5"/>
                    <path d="M59.2965 146.431C59.3545 146.49 59.3998 146.549 59.4577 146.608C59.2663 146.77 59.0749 146.933 58.8837 147.109C58.8448 147.057 58.7996 147.011 58.7546 146.965L59.2965 146.431Z" fill="#F5F5F5"/>
                    <path d="M61.1544 151.624C61.2313 151.69 61.3105 151.857 61.2803 151.913C61.238 151.993 61.0877 152.025 60.9819 152.078C60.9542 151.963 60.8823 151.841 60.9053 151.741C60.9103 151.685 61.1222 151.592 61.1544 151.624Z" fill="#F5F5F5"/>
                    <path d="M72.8608 130.668C72.7421 130.424 72.6109 130.179 72.4861 129.941L73.0397 129.654C73.1153 129.94 73.1973 130.233 73.2729 130.519C73.1293 130.571 72.9982 130.622 72.8608 130.668Z" fill="#F5F5F5"/>
                    <path d="M52.1428 178.071C51.9204 178.15 51.6916 178.222 51.4628 178.294L51.4556 178.297L50.5514 176.675C50.799 176.506 51.0408 176.343 51.2824 176.18L51.3016 176.167C51.6182 176.255 51.9286 176.349 52.4162 176.487C52.3215 177.044 52.2321 177.558 52.1428 178.071Z" fill="#F5F5F5"/>
                    <path d="M47.8917 171.64C48.3578 171.374 48.7183 171.174 49.1471 170.932C48.0357 170.184 48.0357 170.184 47.8917 171.64Z" fill="#F5F5F5"/>
                    <path d="M36.5288 101.374C36.6038 101.635 36.6914 101.897 36.7664 102.158L36.7654 102.159C36.6596 102.199 36.5539 102.239 36.4607 102.279C36.3482 102.029 36.2296 101.785 36.1171 101.535C36.2542 101.477 36.3916 101.432 36.5288 101.374Z" fill="#F5F5F5"/>
                    <path d="M37.5452 96.9702C37.9628 97.0742 38.2833 97.0548 38.3681 97.1903C38.4591 97.3198 38.3018 97.6038 38.25 97.8223C38.0496 97.5816 37.8492 97.3408 37.5452 96.9702Z" fill="#F5F5F5"/>
                    <path d="M59.2538 124.281C58.1091 125.731 56.9417 125.863 55.8462 124.984C55.8634 124.619 55.874 124.248 55.8785 123.882C57.0014 124.013 58.1308 124.15 59.2538 124.281Z" fill="#F5F5F5"/>
                    <path d="M47.4684 119.463C47.5104 119.653 47.5398 119.843 47.5818 120.034C47.3128 120.106 47.057 120.203 46.793 120.218C46.7426 120.217 46.6909 119.868 46.6363 119.677C46.9179 119.606 47.1868 119.534 47.4684 119.463Z" fill="#F5F5F5"/>
                    <path d="M16.2532 116.406C16.1787 116.453 16.0978 116.494 16.0169 116.535L15.8473 116.264C15.9805 116.313 16.1199 116.356 16.2532 116.406Z" fill="#F5F5F5"/>
                    <path d="M-4.7609 128.394C-4.73389 128.915 -4.70929 129.446 -4.68228 129.967C-4.84351 129.999 -5.00475 130.03 -5.16356 130.053C-5.21802 129.533 -5.26171 129.007 -5.31618 128.487C-5.12749 128.454 -4.94959 128.427 -4.7609 128.394Z" fill="#F5F5F5"/>
                    <path d="M-8.54525 128.153L-8.24434 128.103L-8.32323 128.358L-8.54525 128.153Z" fill="#F5F5F5"/>
                    <path d="M3.22388 85.5914C3.3378 85.9276 3.44579 86.2526 3.55137 86.5865L3.52224 86.6073C3.39923 86.6955 3.28445 86.7777 3.16458 86.8504C2.95731 86.5931 2.75207 86.3265 2.54723 86.0605C2.76999 85.9034 3.00112 85.7485 3.22388 85.5914Z" fill="#F5F5F5"/>
                    <path d="M1.19044 81.4678C1.3502 81.9309 1.51237 82.3852 1.67456 82.8394C1.48552 82.9393 1.28815 83.0368 1.10154 83.1277C0.928572 82.68 0.766377 82.2258 0.595841 81.7692C0.793223 81.6717 0.993056 81.5653 1.19044 81.4678Z" fill="#F5F5F5"/>
                    <path d="M32.3639 91.5432C31.6592 91.4619 31.4768 91.4399 31.2944 91.4178C31.3622 91.2368 31.43 91.0557 31.5061 90.877C31.6701 90.9986 31.8316 91.129 32.3639 91.5432Z" fill="#F5F5F5"/>
                    <path d="M26.1777 91.5498C25.3139 91.5576 24.7006 91.5683 23.6362 91.5871C24.5952 90.0924 25.1705 89.1765 26.2438 87.4946C26.2115 89.2749 26.1953 90.2793 26.1777 91.5498Z" fill="#F5F5F5"/>
                    <path d="M19.2512 78.6261C19.2296 79.096 19.3403 79.574 19.1746 79.785C18.4058 80.7519 17.5599 81.6403 16.2107 83.1505C16.5438 81.5488 16.7128 80.7391 16.8867 79.9117C17.6884 79.4774 18.4172 79.08 19.2512 78.6261Z" fill="#F5F5F5"/>
                    <path d="M36.9479 113.629C36.8528 113.356 36.7552 113.091 36.6576 112.826C37.0614 112.728 37.4568 112.629 37.8439 112.527C37.8953 112.731 37.9384 112.934 37.9873 113.147C37.6381 113.307 37.2972 113.469 36.9479 113.629Z" fill="#F5F5F5"/>
                    <path d="M-8.47655 118.74L-8.43342 118.258L-8.42445 118.258C-8.13278 118.263 -7.84111 118.269 -7.55184 118.283L-7.59501 118.766C-7.88967 118.76 -8.18189 118.746 -8.47655 118.74Z" fill="#F5F5F5"/>
                    <path d="M13.5884 130.896C13.5829 130.818 13.5735 130.787 13.5666 130.747C13.5735 130.787 13.5756 130.844 13.5884 130.896Z" fill="#F5F5F5"/>
                    <path d="M33.0162 73.0318L32.6244 74.4554C32.4763 74.3764 32.3199 74.2951 32.1635 74.2138L32.1615 74.2128C32.3322 73.9841 32.5053 73.7466 33.0162 73.0318Z" fill="#F5F5F5"/>
                    <path d="M22.827 111.549C22.8764 111.239 22.9199 110.918 22.9717 110.599C23.4294 110.698 23.887 110.796 24.3255 110.899C24.3126 111.076 24.2938 111.242 24.281 111.419C23.7927 111.465 23.3153 111.504 22.827 111.549Z" fill="#F5F5F5"/>
                    <path d="M32.7067 84.3955C32.4614 84.3084 32.2136 84.2302 31.9683 84.1431L32.0375 83.9244L32.7881 84.1326C32.7554 84.2187 32.7311 84.3071 32.7067 84.3955Z" fill="#F5F5F5"/>
                    <path d="M7.6926 105.162C7.61408 105.121 7.52719 105.078 7.44866 105.037C7.55328 104.885 7.66038 104.724 7.76256 104.581C7.74485 104.776 7.71881 104.968 7.69278 105.16L7.6926 105.162Z" fill="#F5F5F5"/>
                    <path d="M2.17509 122.761C1.97148 122.686 1.77622 122.613 1.58095 122.539C1.77143 122.173 1.96783 121.819 2.16422 121.464C2.2761 121.514 2.3796 121.562 2.49148 121.612L2.17509 122.761Z" fill="#F5F5F5"/>
                    <path d="M13.1087 126.051L12.8831 126.055L12.9569 125.885L13.1087 126.051Z" fill="#F5F5F5"/>
                    <path d="M33.098 110.725C33.1105 110.614 33.1053 110.47 33.163 110.391C33.2183 110.32 33.3483 110.337 33.4438 110.316L33.5126 110.621C33.3788 110.65 33.2342 110.686 33.098 110.725Z" fill="#F5F5F5"/>
                    <path d="M34.974 121.257C33.4633 120.42 33.0799 119.399 34.4218 117.459C35.4285 118.335 35.9318 119.101 35.6733 120.033L38.1469 120.719C38.1546 120.855 38.1563 120.979 38.1556 121.112L35.542 120.387C35.4163 120.66 35.2288 120.948 34.974 121.257Z" fill="#F5F5F5"/>
                    <path d="M4.5997 94.8434L2.40871 93.6074C2.54114 93.3872 2.67355 93.167 2.82265 92.9514C3.52414 93.403 4.23396 93.8568 4.93544 94.3084L4.5997 94.8434Z" fill="#F5F5F5"/>
                    <path d="M9.43289 70.0777C9.51628 70.1009 9.59581 70.1706 9.6719 70.2202C9.62985 70.2752 9.56729 70.372 9.53983 70.3739C9.45644 70.3508 9.37688 70.2812 9.3008 70.2315C9.34285 70.1765 9.40787 70.0708 9.43289 70.0777Z" fill="#F5F5F5"/>
                    <path d="M-2.96601 72.5675C-2.90277 72.5661 -2.82252 72.7312 -2.50256 73.134C-3.06597 72.9017 -3.28991 72.8014 -3.51333 72.7015L-3.51435 72.701C-3.32914 72.6477 -3.14739 72.5743 -2.96601 72.5675Z" fill="#F5F5F5"/>
                    <path d="M18.7302 73.0185C18.1889 72.9635 17.6367 72.9151 17.0953 72.8601L17.1851 72.1426L18.8051 72.4206C18.7765 72.6221 18.7587 72.8171 18.7302 73.0185Z" fill="#F5F5F5"/>
                    <path d="M12.8982 69.5575L12.9539 69.5794C12.9261 69.6478 12.9091 69.7096 12.8862 69.7604L12.1023 69.5429C12.135 69.4568 12.1618 69.3595 12.1944 69.2734C12.4248 69.3726 12.6645 69.4662 12.8982 69.5575Z" fill="#F5F5F5"/>
                    <path d="M18.1399 115.664L18.4361 114.947C19.0538 115.214 19.674 115.471 20.2835 115.736C20.1952 115.959 20.0985 116.179 20.0103 116.402C19.3876 116.153 18.7625 115.913 18.1399 115.664Z" fill="#F5F5F5"/>
                    <path d="M24.4877 117.549C24.9266 117.357 25.2303 117.232 25.7696 117C25.757 117.568 25.7382 117.962 25.6912 118.947L25.6911 118.949C25.0793 118.237 24.8208 117.936 24.4877 117.549Z" fill="#F5F5F5"/>
                    <path d="M20.9437 117.87C20.9218 117.949 20.894 118.018 20.8721 118.097C20.6351 118.013 20.3981 117.928 20.1504 117.85C20.1757 117.79 20.1928 117.728 20.2098 117.666L20.9437 117.87Z" fill="#F5F5F5"/>
                    <path d="M17.3823 122.069C17.3639 122.168 17.2593 122.32 17.196 122.322C17.1053 122.325 17.0022 122.211 16.9035 122.145C16.9886 122.064 17.0585 121.941 17.1565 121.911C17.2079 121.887 17.3945 122.025 17.3823 122.069Z" fill="#F5F5F5"/>
                    <path d="M41.3853 121.77C41.5368 121.546 41.6824 121.31 41.8256 121.083L42.3524 121.42C42.1424 121.628 41.9301 121.845 41.7202 122.053C41.6038 121.955 41.4933 121.867 41.3853 121.77Z" fill="#F5F5F5"/>
                    <path d="M-10.0185 127.44C-10.1983 127.286 -10.3757 127.124 -10.5531 126.962L-10.5587 126.957L-9.60876 125.365C-9.33867 125.495 -9.07648 125.624 -8.81452 125.752L-8.79365 125.762C-8.71066 126.08 -8.63599 126.396 -8.51098 126.887C-9.04037 127.083 -9.52972 127.262 -10.0185 127.44Z" fill="#F5F5F5"/>
                    <path d="M-6.58696 120.55C-6.12312 120.821 -5.7687 121.033 -5.34412 121.284C-5.25459 119.948 -5.25482 119.948 -6.58696 120.55Z" fill="#F5F5F5"/>
                    <path d="M48.5085 75.6734C48.3201 75.8686 48.1377 76.075 47.9493 76.2702L47.9484 76.2695C47.8608 76.1978 47.7732 76.1261 47.6915 76.0655C47.8514 75.8433 48.0029 75.6189 48.1628 75.3968C48.2816 75.4868 48.3897 75.5834 48.5085 75.6734Z" fill="#F5F5F5"/>
                    <path d="M52.8286 74.3592C52.9481 74.7731 53.1256 75.0413 53.0508 75.1823C52.9844 75.3257 52.6599 75.3309 52.4448 75.3949C52.5525 75.1012 52.6603 74.8075 52.8286 74.3592Z" fill="#F5F5F5"/>
                    <path d="M40.0869 106.791C38.2581 106.522 37.5581 105.576 37.7696 104.188C38.0938 104.021 38.4205 103.845 38.7388 103.667C39.189 104.705 39.6367 105.753 40.0869 106.791Z" fill="#F5F5F5"/>
                    <path d="M38.345 94.1731C38.2011 94.3045 38.0513 94.4248 37.9074 94.5562C37.7105 94.3588 37.4978 94.1857 37.3523 93.9644C37.3286 93.9198 37.6043 93.7012 37.7422 93.5586C37.9451 93.7672 38.1421 93.9646 38.345 94.1731Z" fill="#F5F5F5"/>
                    <path d="M25.3347 65.5902C25.2562 65.5494 25.18 65.4997 25.1039 65.4501L25.2535 65.1679C25.2778 65.3079 25.3104 65.4502 25.3347 65.5902Z" fill="#F5F5F5"/>
                    </g>
                    </g>
                    </svg>
                </span>
                <span class="list-btn__icon">
                    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.6559 10C25.6642 10 27.2923 8.37197 27.2923 6.36366C27.2923 4.35535 25.6642 2.72729 23.6559 2.72729C21.6476 2.72729 20.0195 4.35535 20.0195 6.36366C20.0195 8.37197 21.6476 10 23.6559 10Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.2013 18.1818C10.2096 18.1818 11.8377 16.5537 11.8377 14.5454C11.8377 12.5371 10.2096 10.9091 8.2013 10.9091C6.193 10.9091 4.56494 12.5371 4.56494 14.5454C4.56494 16.5537 6.193 18.1818 8.2013 18.1818Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M23.6559 27.2727C25.6642 27.2727 27.2923 25.6447 27.2923 23.6364C27.2923 21.6281 25.6642 20 23.6559 20C21.6476 20 20.0195 21.6281 20.0195 23.6364C20.0195 25.6447 21.6476 27.2727 23.6559 27.2727Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.8376 17.2727L20.0195 21.8182" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M20.0195 8.18176L11.8376 12.7272" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
            </button>
            ${this._useNative
                ? ''
                : html`
                      <ul class="list">
                          ${this.facebook && this.facebookReady && this.facebookAppId
                              ? this.li(
                                    html`<svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.00878906 8.88932C0.00878906 3.97988 3.98794 0 8.89646 0C13.805 0 17.7841 3.97988 17.7841 8.88932C17.7841 13.5996 14.1212 17.4542 9.48897 17.7592V10.6672H11.859V9.48194H9.48897V7.70407C9.48897 6.72219 10.2848 5.92621 11.2665 5.92621H11.859V4.74097H11.2665C9.63033 4.74097 8.30395 6.0676 8.30395 7.70407V9.48194H5.9339V10.6672H8.30395V17.7592C3.67168 17.4542 0.00878906 13.5996 0.00878906 8.88932Z"
                                            fill="#94A1BD"
                                        />
                                    </svg>`,
                                    'Facebook',
                                    shareFacebook,
                                )
                              : ''}
                          ${this.vk
                              ? this.li(
                                    html`<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
                                    <path d="M16 0C7.164 0 0 7.164 0 16C0 24.836 7.164 32 16 32C24.836 32 32 24.836 32 16C32 7.164 24.836 0 16 0ZM24.4813 21.2893L21.9907 21.324C21.9907 21.324 21.4533 21.4293 20.7507 20.944C19.8187 20.304 18.94 18.6387 18.2547 18.8547C17.56 19.076 17.5813 20.5747 17.5813 20.5747C17.5813 20.5747 17.5867 20.8387 17.428 21.0107C17.256 21.196 16.9187 21.1773 16.9187 21.1773H15.8027C15.8027 21.1773 13.3413 21.3813 11.172 19.12C8.80933 16.6547 6.72133 11.7893 6.72133 11.7893C6.72133 11.7893 6.59867 11.4827 6.72933 11.3253C6.87733 11.152 7.276 11.1467 7.276 11.1467L9.94267 11.1333C9.94267 11.1333 10.1933 11.1773 10.3747 11.3107C10.5227 11.42 10.6067 11.6253 10.6067 11.6253C10.6067 11.6253 11.0373 12.7173 11.6093 13.7067C12.724 15.6347 13.2413 16.0573 13.62 15.8507C14.172 15.5493 14.0067 13.1253 14.0067 13.1253C14.0067 13.1253 14.0173 12.2453 13.7293 11.8533C13.5053 11.548 13.084 11.4573 12.9 11.4347C12.7493 11.4147 12.9947 11.0653 13.3147 10.908C13.7947 10.6733 14.6427 10.66 15.644 10.6693C16.424 10.6773 16.6493 10.7253 16.9533 10.8C17.8733 11.0227 17.5613 11.8813 17.5613 13.9413C17.5613 14.6013 17.4427 15.5293 17.9173 15.8347C18.1227 15.9667 18.6227 15.8547 19.8693 13.7293C20.4627 12.7213 20.9067 11.5373 20.9067 11.5373C20.9067 11.5373 21.004 11.3267 21.1547 11.236C21.3093 11.1427 21.5173 11.172 21.5173 11.172L24.324 11.1547C24.324 11.1547 25.1667 11.0533 25.304 11.436C25.448 11.836 24.9893 12.7707 23.8427 14.3C21.96 16.812 21.7507 16.5787 23.3147 18.032C24.808 19.42 25.116 20.0947 25.168 20.18C25.7853 21.208 24.4813 21.2893 24.4813 21.2893Z" fill="#303030"/>
                                    </mask>
                                    <g>
                                    <path d="M16 0C7.164 0 0 7.164 0 16C0 24.836 7.164 32 16 32C24.836 32 32 24.836 32 16C32 7.164 24.836 0 16 0ZM24.4813 21.2893L21.9907 21.324C21.9907 21.324 21.4533 21.4293 20.7507 20.944C19.8187 20.304 18.94 18.6387 18.2547 18.8547C17.56 19.076 17.5813 20.5747 17.5813 20.5747C17.5813 20.5747 17.5867 20.8387 17.428 21.0107C17.256 21.196 16.9187 21.1773 16.9187 21.1773H15.8027C15.8027 21.1773 13.3413 21.3813 11.172 19.12C8.80933 16.6547 6.72133 11.7893 6.72133 11.7893C6.72133 11.7893 6.59867 11.4827 6.72933 11.3253C6.87733 11.152 7.276 11.1467 7.276 11.1467L9.94267 11.1333C9.94267 11.1333 10.1933 11.1773 10.3747 11.3107C10.5227 11.42 10.6067 11.6253 10.6067 11.6253C10.6067 11.6253 11.0373 12.7173 11.6093 13.7067C12.724 15.6347 13.2413 16.0573 13.62 15.8507C14.172 15.5493 14.0067 13.1253 14.0067 13.1253C14.0067 13.1253 14.0173 12.2453 13.7293 11.8533C13.5053 11.548 13.084 11.4573 12.9 11.4347C12.7493 11.4147 12.9947 11.0653 13.3147 10.908C13.7947 10.6733 14.6427 10.66 15.644 10.6693C16.424 10.6773 16.6493 10.7253 16.9533 10.8C17.8733 11.0227 17.5613 11.8813 17.5613 13.9413C17.5613 14.6013 17.4427 15.5293 17.9173 15.8347C18.1227 15.9667 18.6227 15.8547 19.8693 13.7293C20.4627 12.7213 20.9067 11.5373 20.9067 11.5373C20.9067 11.5373 21.004 11.3267 21.1547 11.236C21.3093 11.1427 21.5173 11.172 21.5173 11.172L24.324 11.1547C24.324 11.1547 25.1667 11.0533 25.304 11.436C25.448 11.836 24.9893 12.7707 23.8427 14.3C21.96 16.812 21.7507 16.5787 23.3147 18.032C24.808 19.42 25.116 20.0947 25.168 20.18C25.7853 21.208 24.4813 21.2893 24.4813 21.2893Z" fill="#303030"/>
                                    <g style="mix-blend-mode:color-dodge">
                                    <path d="M78.7609 24.2878C79.2586 24.1226 79.7654 23.9574 80.2631 23.7922C80.3368 23.94 80.4105 24.0878 80.4751 24.2356C79.9866 24.427 79.489 24.6096 79.0005 24.8009C78.9176 24.6269 78.8438 24.4617 78.7609 24.2878Z" fill="#F5F5F5"/>
                                    <path d="M79.5442 28.0182L79.4152 27.74L79.6825 27.7487L79.5442 28.0182Z" fill="#F5F5F5"/>
                                    <path d="M35.1692 27.9661C35.4641 27.7661 35.7499 27.5748 36.0448 27.3835L36.0728 27.4061C36.1912 27.5018 36.3017 27.591 36.4043 27.6878C36.2108 27.9572 36.0078 28.2271 35.8052 28.4965C35.5932 28.3226 35.3812 28.14 35.1692 27.9661Z" fill="#F5F5F5"/>
                                    <path d="M31.7225 31.0356C32.128 30.7574 32.5243 30.4791 32.9206 30.2009C33.068 30.3574 33.2155 30.5226 33.3537 30.6791C32.9666 30.9661 32.5703 31.2443 32.174 31.5313C32.0266 31.3661 31.8699 31.2008 31.7225 31.0356Z" fill="#F5F5F5"/>
                                    <path d="M33.1049 -1.8513C33.2155 -1.14695 33.2432 -0.964348 33.2708 -0.781738C33.0773 -0.799129 32.8837 -0.816542 32.6902 -0.842628C32.7639 -1.03393 32.8468 -1.22521 33.1049 -1.8513Z" fill="#F5F5F5"/>
                                    <path d="M34.773 4.14C35.0126 4.97478 35.1878 5.56608 35.4919 6.59217C33.7869 6.06174 32.7455 5.74868 30.8285 5.15738C32.5611 4.7139 33.5381 4.46174 34.773 4.14Z" fill="#F5F5F5"/>
                                    <path d="M24.1193 14.2965C24.5801 14.1921 25.0132 13.9574 25.262 14.0617C26.4048 14.5487 27.4923 15.1313 29.3171 16.0356C27.6767 16.14 26.8472 16.1922 25.9994 16.2443C25.3635 15.5835 24.7828 14.9835 24.1193 14.2965Z" fill="#F5F5F5"/>
                                    <path d="M53.2602 -12.1817C53.0206 -12.0165 52.7902 -11.8513 52.5598 -11.6861C52.357 -12.0513 52.1544 -12.4078 51.9516 -12.7556C52.1359 -12.86 52.3202 -12.9557 52.5138 -13.06C52.7626 -12.7644 53.0114 -12.4774 53.2602 -12.1817Z" fill="#F5F5F5"/>
                                    <path d="M70.4112 30.4617L69.932 30.5487L69.9298 30.5399C69.8568 30.2559 69.7838 29.9719 69.72 29.6878L70.1992 29.6008C70.2729 29.8878 70.3374 30.1748 70.4112 30.4617Z" fill="#F5F5F5"/>
                                    <path d="M76.2542 5.84435C76.1805 5.87043 76.1528 5.88781 76.1159 5.9052C76.1528 5.88781 76.2081 5.87043 76.2542 5.84435Z" fill="#F5F5F5"/>
                                    <path d="M15.0047 2.45302H16.4884C16.4517 2.61753 16.415 2.79068 16.3783 2.96385L16.3778 2.96608C16.1106 2.86173 15.8341 2.75737 15.0047 2.45302Z" fill="#F5F5F5"/>
                                    <path d="M55.039 2.05303C54.7256 2.08781 54.403 2.13132 54.0805 2.1661C54.0528 1.69654 54.0252 1.22695 54.0068 0.77478C54.1819 0.739996 54.3477 0.713882 54.5228 0.6791C54.6979 1.13997 54.8639 1.59216 55.039 2.05303Z" fill="#F5F5F5"/>
                                    <path d="M26.0915 -0.27739C26.0731 -0.0165195 26.0639 0.244356 26.0455 0.505226L25.8151 0.496513V-0.286098C25.9073 -0.277403 25.9993 -0.27739 26.0915 -0.27739Z" fill="#F5F5F5"/>
                                    <path d="M52.9192 18.4183C52.9008 18.5052 52.8824 18.6009 52.864 18.6878C52.6889 18.627 52.5045 18.5661 52.3386 18.5052C52.5317 18.4705 52.7248 18.4445 52.9179 18.4184L52.9192 18.4183Z" fill="#F5F5F5"/>
                                    <path d="M71.4433 19.0704C71.4249 19.2878 71.4065 19.4965 71.3881 19.7052C70.9826 19.6182 70.5863 19.5226 70.19 19.4269C70.2084 19.3052 70.2269 19.1922 70.2453 19.0704H71.4433Z" fill="#F5F5F5"/>
                                    <path d="M71.6922 7.60087L71.7568 7.81827L71.5724 7.79217L71.6922 7.60087Z" fill="#F5F5F5"/>
                                    <path d="M51.4816 -7.6774C51.371 -7.66001 51.2328 -7.61651 51.1406 -7.65129C51.0577 -7.68608 51.0392 -7.81653 50.9931 -7.90348L51.2696 -8.05132C51.3342 -7.92958 51.4079 -7.79914 51.4816 -7.6774Z" fill="#F5F5F5"/>
                                    <path d="M61.1768 -12.3035C60.7713 -10.6165 59.8865 -9.97306 57.647 -10.7557C58.2253 -11.9647 58.8317 -12.6564 59.8036 -12.6545V-15.2339C59.9326 -15.2774 60.0524 -15.3122 60.1814 -15.347V-12.6217C60.4791 -12.5725 60.8085 -12.4677 61.1768 -12.3035Z" fill="#F5F5F5"/>
                                    <path d="M43.7586 24.1661L43.1503 26.6182C42.9015 26.5487 42.6527 26.4791 42.4039 26.3922C42.6527 25.5922 42.9015 24.7835 43.1503 23.9835L43.7586 24.1661Z" fill="#F5F5F5"/>
                                    <path d="M18.4791 26.0878C18.4791 26.0008 18.5252 25.9052 18.5529 25.8182C18.6174 25.8443 18.728 25.8791 18.7372 25.9052C18.7372 25.9922 18.6911 26.0878 18.6635 26.1748C18.599 26.1487 18.4791 26.1139 18.4791 26.0878Z" fill="#F5F5F5"/>
                                    <path d="M24.2206 37.4357C24.2022 37.3748 24.3405 37.253 24.6446 36.8356C24.571 37.4434 24.5341 37.6871 24.4973 37.9302L24.4972 37.9313C24.3958 37.7661 24.2759 37.6096 24.2206 37.4357Z" fill="#F5F5F5"/>
                                    <path d="M18.8293 16.2965C18.9215 16.8357 19.0229 17.3835 19.1151 17.9226L18.3962 18.0269L18.2302 16.3835C18.433 16.3574 18.6266 16.3226 18.8293 16.2965Z" fill="#F5F5F5"/>
                                    <path d="M17.0446 22.8694L17.0507 22.8096C17.1244 22.8183 17.1889 22.8182 17.2442 22.8269V23.6443C17.152 23.6356 17.0506 23.6356 16.9584 23.6269C16.9926 23.3773 17.0189 23.1202 17.0446 22.8694Z" fill="#F5F5F5"/>
                                    <path d="M60.2828 5.49651L59.5087 5.40086C59.6008 4.73129 59.6838 4.06173 59.776 3.40086C60.0156 3.42694 60.2551 3.46173 60.4947 3.48782C60.421 4.15738 60.3565 4.82694 60.2828 5.49651Z" fill="#F5F5F5"/>
                                    <path d="M60.4026 -1.15565C60.0985 -1.52957 59.8958 -1.79045 59.5271 -2.25132C60.0797 -2.39035 60.4666 -2.47726 61.4328 -2.69433L61.4348 -2.69477C60.9095 -1.91217 60.6883 -1.58174 60.4026 -1.15565Z" fill="#F5F5F5"/>
                                    <path d="M61.6652 2.19218C61.7481 2.19218 61.8219 2.20086 61.9048 2.20086C61.8864 2.45303 61.868 2.70521 61.8588 2.96608C61.7944 2.9574 61.7296 2.9574 61.6652 2.9574V2.19218Z" fill="#F5F5F5"/>
                                    <path d="M66.688 4.52259C66.7893 4.5139 66.9644 4.57477 66.9828 4.63564C67.0105 4.7226 66.9276 4.85304 66.8907 4.96608C66.7893 4.90521 66.6511 4.87042 66.5958 4.78347C66.559 4.73999 66.6419 4.52259 66.688 4.52259Z" fill="#F5F5F5"/>
                                    <path d="M59.951 -18.6513C59.693 -18.7383 59.4257 -18.8165 59.1677 -18.8948L59.352 -19.4948C59.61 -19.3469 59.8774 -19.1991 60.1354 -19.0513C60.0709 -18.9122 60.0155 -18.7817 59.951 -18.6513Z" fill="#F5F5F5"/>
                                    <path d="M79.2494 29.6357C79.149 29.8508 79.0396 30.0659 78.9302 30.2811L78.9267 30.2878L77.1296 29.7922C77.1835 29.4957 77.2374 29.2075 77.2913 28.9194L77.2956 28.8965C77.5813 28.7313 77.867 28.5748 78.3093 28.3226C78.641 28.7833 78.9454 29.2097 79.2494 29.6357Z" fill="#F5F5F5"/>
                                    <path d="M71.6554 28.1487C71.7936 27.6269 71.9042 27.2269 72.0332 26.7487C70.7154 27.0182 70.7155 27.0184 71.6554 28.1487Z" fill="#F5F5F5"/>
                                    <path d="M13.4011 -13.26C13.6407 -13.1296 13.8896 -13.0078 14.1292 -12.8774L14.1287 -12.8764C14.0828 -12.7724 14.0369 -12.6684 14.0001 -12.5731C13.7421 -12.6687 13.4841 -12.7557 13.226 -12.8513C13.2813 -12.9905 13.3458 -13.1209 13.4011 -13.26Z" fill="#F5F5F5"/>
                                    <path d="M10.968 -17.0948C11.3367 -17.3209 11.5487 -17.5644 11.7054 -17.5296C11.862 -17.5035 11.9542 -17.1904 12.074 -16.9991C11.7607 -17.0252 11.4473 -17.0513 10.968 -17.0948Z" fill="#F5F5F5"/>
                                    <path d="M45.7953 -13.3991C46.0257 -11.5557 45.2977 -10.6252 43.8969 -10.46C43.648 -10.7296 43.3899 -10.9991 43.1319 -11.26C44.0166 -11.973 44.9106 -12.6861 45.7953 -13.3991Z" fill="#F5F5F5"/>
                                    <path d="M34.0449 -8.34694C34.2108 -8.2426 34.3675 -8.12956 34.5334 -8.02521C34.3951 -7.78173 34.2846 -7.52956 34.1095 -7.32956C34.0726 -7.29478 33.7869 -7.50349 33.6118 -7.59914C33.7592 -7.85132 33.8974 -8.09477 34.0449 -8.34694Z" fill="#F5F5F5"/>
                                    <path d="M9.86217 11.8791C9.84374 11.9661 9.81613 12.0531 9.78848 12.14L9.47506 12.0704C9.60408 12.0095 9.73314 11.94 9.86217 11.8791Z" fill="#F5F5F5"/>
                                    <path d="M3.62691 35.4016C4.01882 35.75 4.41536 36.1064 4.80727 36.4548C4.71612 36.5925 4.62494 36.7303 4.52918 36.8601C4.11928 36.5327 3.71232 36.193 3.30242 35.8657C3.41157 35.7069 3.51777 35.5604 3.62691 35.4016Z" fill="#F5F5F5"/>
                                    <path d="M0.787937 37.9453L0.964378 37.6944L1.09053 37.9302L0.787937 37.9453Z" fill="#F5F5F5"/>
                                    <path d="M-21.3544 -0.510715C-21.0337 -0.355316 -20.7252 -0.203488 -20.412 -0.0437393L-20.4176 -0.00814056C-20.4413 0.142222 -20.4634 0.282533 -20.4959 0.419716C-20.8259 0.386909 -21.1612 0.346051 -21.4958 0.305271C-21.4511 0.0347459 -21.399 -0.24019 -21.3544 -0.510715Z" fill="#F5F5F5"/>
                                    <path d="M-25.7361 -1.96093C-25.2923 -1.74888 -24.8532 -1.54477 -24.4141 -1.3407C-24.4759 -1.13474 -24.5453 -0.924461 -24.6117 -0.726482C-25.0537 -0.918218 -25.4929 -1.1223 -25.9395 -1.32202C-25.8702 -1.53233 -25.8054 -1.75062 -25.7361 -1.96093Z" fill="#F5F5F5"/>
                                    <path d="M3.43604 -17.2072C2.88135 -16.7592 2.73706 -16.644 2.59274 -16.5287C2.51104 -16.705 2.42935 -16.8813 2.35517 -17.062C2.55771 -17.0938 2.76481 -17.1176 3.43604 -17.2072Z" fill="#F5F5F5"/>
                                    <path d="M-0.918499 -12.7669C-1.52163 -12.142 -1.94615 -11.6947 -2.68271 -10.9182C-3.07582 -12.66 -3.32543 -13.7185 -3.77181 -15.6742C-2.52144 -14.3955 -1.8146 -13.6755 -0.918499 -12.7669Z" fill="#F5F5F5"/>
                                    <path d="M-15.0412 -16.915C-14.7204 -16.5682 -14.3005 -16.3104 -14.2664 -16.0428C-14.1168 -14.8096 -14.0776 -13.5765 -13.9484 -11.544C-14.859 -12.9125 -15.3189 -13.6047 -15.788 -14.3129C-15.5336 -15.1941 -15.3043 -15.9969 -15.0412 -16.915Z" fill="#F5F5F5"/>
                                    <path d="M22.4601 -4.91736C22.1972 -5.04226 21.939 -5.15921 21.6807 -5.27613C21.8956 -5.63433 22.103 -5.9881 22.3029 -6.3376C22.4854 -6.23015 22.6604 -6.11837 22.8475 -6.00293C22.7159 -5.63962 22.5918 -5.28068 22.4601 -4.91736Z" fill="#F5F5F5"/>
                                    <path d="M-5.8947 31.2575L-6.20958 30.886L-6.20314 30.8797C-5.99364 30.6745 -5.78414 30.4693 -5.57008 30.272L-5.25514 30.6435C-5.46679 30.8508 -5.68305 31.0502 -5.8947 31.2575Z" fill="#F5F5F5"/>
                                    <path d="M18.3461 24.009C18.2866 23.9582 18.2577 23.9429 18.2242 23.9197C18.2577 23.9429 18.3004 23.9822 18.3461 24.009Z" fill="#F5F5F5"/>
                                    <path d="M-9.34171 -30.7303L-8.59983 -29.4453C-8.76066 -29.3948 -8.92896 -29.34 -9.09728 -29.2853L-9.09945 -29.2845C-9.14271 -29.5682 -9.19057 -29.8598 -9.34171 -30.7303Z" fill="#F5F5F5"/>
                                    <path d="M11.0218 3.74045C10.835 3.48648 10.6361 3.22886 10.4447 2.9669C10.8375 2.70818 11.2304 2.44947 11.6127 2.20742C11.7304 2.34167 11.8359 2.47223 11.9536 2.60649C11.642 2.98856 11.3334 3.35837 11.0218 3.74045Z" fill="#F5F5F5"/>
                                    <path d="M-1.43368 -22.494C-1.66882 -22.3795 -1.89931 -22.257 -2.13445 -22.1425L-2.24211 -22.3464L-1.56434 -22.7377C-1.52579 -22.6536 -1.47976 -22.5738 -1.43368 -22.494Z" fill="#F5F5F5"/>
                                    <path d="M-4.21072 10.0873C-4.29524 10.1148 -4.38728 10.1468 -4.47181 10.1743C-4.50664 9.9922 -4.54609 9.80207 -4.57632 9.62797C-4.44972 9.77784 -4.33063 9.93206 -4.21155 10.0863L-4.21072 10.0873Z" fill="#F5F5F5"/>
                                    <path d="M4.48653 26.4558C4.28905 26.5485 4.09915 26.6369 3.9092 26.7253C3.78175 26.3307 3.66643 25.9396 3.55112 25.5486C3.66577 25.5037 3.77288 25.4632 3.88752 25.4183L4.48653 26.4558Z" fill="#F5F5F5"/>
                                    <path d="M14.5439 20.9365L14.3879 21.1011L14.3183 20.9284L14.5439 20.9365Z" fill="#F5F5F5"/>
                                    <path d="M17.67 -4.20553C17.5996 -4.29261 17.4928 -4.39057 17.4769 -4.48778C17.4655 -4.577 17.5693 -4.65822 17.6215 -4.7416L17.8878 -4.57604C17.8146 -4.4593 17.7385 -4.33025 17.67 -4.20553Z" fill="#F5F5F5"/>
                                    <path d="M26.5239 1.87775C24.8602 2.37006 23.8605 1.92552 23.4185 -0.405235C24.7547 -0.508959 25.657 -0.329695 26.1413 0.512936L28.3751 -0.776768C28.4772 -0.686768 28.5673 -0.600375 28.6619 -0.506031L26.3017 0.856621C26.408 1.13899 26.482 1.47662 26.5239 1.87775Z" fill="#F5F5F5"/>
                                    <path d="M-13.7688 5.02789L-16.1966 5.72719C-16.2608 5.47691 -16.3249 5.22668 -16.374 4.96771C-15.5568 4.7832 -14.7321 4.59431 -13.9148 4.40981L-13.7688 5.02789Z" fill="#F5F5F5"/>
                                    <path d="M-28.0728 -15.9039C-27.9975 -15.9474 -27.8916 -15.9553 -27.8025 -15.9748C-27.7928 -15.9059 -27.7677 -15.7928 -27.7856 -15.7717C-27.8609 -15.7283 -27.9668 -15.7203 -28.0559 -15.7008C-28.0656 -15.7697 -28.0954 -15.8909 -28.0728 -15.9039Z" fill="#F5F5F5"/>
                                    <path d="M-35.0296 -5.25769C-34.9861 -5.30408 -34.8115 -5.24518 -34.2979 -5.19049C-34.8611 -4.95036 -35.0906 -4.86048 -35.3195 -4.7708L-35.3205 -4.77039C-35.2282 -4.94079 -35.1525 -5.12284 -35.0296 -5.25769Z" fill="#F5F5F5"/>
                                    <path d="M-19.4182 -20.4963C-19.839 -20.1469 -20.2628 -19.7851 -20.6836 -19.4358L-21.1334 -20.0062L-19.793 -20.9716C-19.6691 -20.8091 -19.5422 -20.6588 -19.4182 -20.4963Z" fill="#F5F5F5"/>
                                    <path d="M-26.0029 -18.7555L-25.948 -18.7801C-25.9186 -18.7119 -25.8864 -18.6561 -25.8663 -18.6038L-26.5742 -18.1951C-26.6127 -18.2793 -26.6634 -18.3671 -26.702 -18.4513C-26.4687 -18.5466 -26.2329 -18.6523 -26.0029 -18.7555Z" fill="#F5F5F5"/>
                                    <path d="M10.6616 10.0035L10.3574 9.28526C10.9833 9.0303 11.6047 8.76737 12.2231 8.51675C12.3203 8.73731 12.41 8.96214 12.5072 9.1827C11.8904 9.45363 11.2783 9.73256 10.6616 10.0035Z" fill="#F5F5F5"/>
                                    <path d="M16.4825 6.78117C16.6542 6.33083 16.7788 6.02484 16.9936 5.47515C17.3903 5.88418 17.659 6.17577 18.3301 6.90401L18.3315 6.90551C17.3911 6.84188 16.9943 6.81554 16.4825 6.78117Z" fill="#F5F5F5"/>
                                    <path d="M14.2145 9.54852C14.2559 9.62035 14.2853 9.68855 14.3267 9.76038C14.0991 9.8705 13.8716 9.98069 13.641 10.1031C13.6164 10.043 13.584 9.98691 13.5518 9.93113L14.2145 9.54852Z" fill="#F5F5F5"/>
                                    <path d="M14.7076 15.0636C14.7659 15.147 14.8007 15.3291 14.7572 15.3755C14.6957 15.4429 14.5413 15.4363 14.4249 15.4609C14.427 15.3427 14.388 15.2056 14.4357 15.1142C14.4549 15.0606 14.6846 15.0237 14.7076 15.0636Z" fill="#F5F5F5"/>
                                    <path d="M31.4084 -2.35772C31.3546 -2.62468 31.2888 -2.89525 31.2275 -3.15785L31.8393 -3.29827C31.8403 -3.00088 31.846 -2.69544 31.847 -2.39805C31.6942 -2.38435 31.5536 -2.36707 31.4084 -2.35772Z" fill="#F5F5F5"/>
                                    <path d="M-0.760208 38.4986C-0.996689 38.5193 -1.2377 38.5321 -1.47875 38.5448L-1.48631 38.5452L-1.95562 36.7411C-1.67192 36.6395 -1.39535 36.5421 -1.11899 36.4448L-1.09698 36.437C-0.811053 36.6018 -0.532656 36.7709 -0.0930862 37.028C-0.326237 37.5455 -0.543357 38.0223 -0.760208 38.4986Z" fill="#F5F5F5"/>
                                    <path d="M-3.26944 31.1785C-2.74848 31.0374 -2.34681 30.9331 -1.86811 30.8057C-2.76046 29.7992 -2.76055 29.7994 -3.26944 31.1785Z" fill="#F5F5F5"/>
                                    <path d="M3.4644 -39.9755C3.47125 -39.7028 3.49024 -39.4264 3.49709 -39.1537L3.496 -39.1536C3.38296 -39.1413 3.26995 -39.1291 3.169 -39.1133C3.12281 -39.3846 3.06912 -39.6515 3.02293 -39.9228C3.17107 -39.9445 3.31626 -39.9539 3.4644 -39.9755Z" fill="#F5F5F5"/>
                                    <path d="M5.5689 -44C5.94902 -43.7938 6.26588 -43.7319 6.31409 -43.5788C6.36983 -43.4301 6.14481 -43.1938 6.03904 -42.9943C5.90496 -43.2788 5.77086 -43.5632 5.5689 -44Z" fill="#F5F5F5"/>
                                    <path d="M19.782 -11.9909C18.3007 -10.8696 17.1309 -11.0349 16.2874 -12.1654C16.3964 -12.5157 16.5008 -12.874 16.5977 -13.2279C17.6576 -12.8182 18.7221 -12.4006 19.782 -11.9909Z" fill="#F5F5F5"/>
                                    <path d="M9.53146 -19.6409C9.52404 -19.4451 9.50449 -19.2529 9.49707 -19.057C9.21709 -19.055 8.94343 -19.0247 8.68267 -19.0763C8.63412 -19.0908 8.672 -19.4426 8.66729 -19.6421C8.9594 -19.6405 9.23934 -19.6425 9.53146 -19.6409Z" fill="#F5F5F5"/>
                                    <path d="M-20.0762 -30.4708C-20.1607 -30.4432 -20.2498 -30.4237 -20.339 -30.4041L-20.4354 -30.7104C-20.3182 -30.6291 -20.1934 -30.5521 -20.0762 -30.4708Z" fill="#F5F5F5"/>
                                    </g>
                                    </g>
                                    </svg>`,
                                    'VK',
                                    shareVK,
                                )
                              : ''}
                          ${this.twitter
                              ? this.li(
                                    html`<svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.00878906 8.88932C0.00878906 3.97988 3.98794 0 8.89646 0C13.805 0 17.7841 3.97988 17.7841 8.88932C17.7841 13.5996 14.1212 17.4542 9.48897 17.7592V10.6672H11.859V9.48194H9.48897V7.70407C9.48897 6.72219 10.2848 5.92621 11.2665 5.92621H11.859V4.74097H11.2665C9.63033 4.74097 8.30395 6.0676 8.30395 7.70407V9.48194H5.9339V10.6672H8.30395V17.7592C3.67168 17.4542 0.00878906 13.5996 0.00878906 8.88932Z"
                                            fill="#94A1BD"
                                        />
                                    </svg>`,
                                    'Twitter',
                                    shareTwitter,
                                )
                              : ''}
                          ${this.ok
                              ? this.li(
                                    html`<svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.00878906 8.88932C0.00878906 3.97988 3.98794 0 8.89646 0C13.805 0 17.7841 3.97988 17.7841 8.88932C17.7841 13.5996 14.1212 17.4542 9.48897 17.7592V10.6672H11.859V9.48194H9.48897V7.70407C9.48897 6.72219 10.2848 5.92621 11.2665 5.92621H11.859V4.74097H11.2665C9.63033 4.74097 8.30395 6.0676 8.30395 7.70407V9.48194H5.9339V10.6672H8.30395V17.7592C3.67168 17.4542 0.00878906 13.5996 0.00878906 8.88932Z"
                                            fill="#94A1BD"
                                        />
                                    </svg>`,
                                    'Odnoklassniki',
                                    shareOK,
                                )
                              : ''}
                          ${this.telegram
                              ? this.li(
                                    html`<svg width="36" height="33" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="33">
                                    <path d="M34.9097 1.2416C34.3097 0.7328 33.3673 0.66 32.3929 1.0512H32.3913C31.3665 1.4624 3.38246 13.4656 2.24326 13.956C2.03606 14.028 0.226461 14.7032 0.412861 16.2072C0.579261 17.5632 2.03366 18.1248 2.21126 18.1896L9.32566 20.6256C9.79766 22.1968 11.5377 27.9936 11.9225 29.232C12.1625 30.004 12.5537 31.0184 13.2393 31.2272C13.8409 31.4592 14.4393 31.2472 14.8265 30.9432L19.1761 26.9088L26.1977 32.3848L26.3649 32.4848C26.8417 32.696 27.2985 32.8016 27.7345 32.8016C28.0713 32.8016 28.3945 32.7384 28.7033 32.612C29.7553 32.18 30.1761 31.1776 30.2201 31.064L35.4649 3.8024C35.7849 2.3464 35.3401 1.6056 34.9097 1.2416ZM15.6001 21.6L13.2001 28L10.8001 20L29.2001 6.4L15.6001 21.6Z" fill="#303030"/>
                                    </mask>
                                    <g>
                                    <path d="M34.9087 1.24154C34.3087 0.732741 33.3663 0.659941 32.3919 1.05114H32.3903C31.3655 1.46234 3.38148 13.4655 2.24228 13.9559C2.03508 14.0279 0.225485 14.7031 0.411885 16.2071C0.578285 17.5631 2.03268 18.1247 2.21028 18.1895L9.32468 20.6255C9.79668 22.1967 11.5367 27.9935 11.9215 29.2319C12.1615 30.0039 12.5527 31.0183 13.2383 31.2271C13.8399 31.4591 14.4383 31.2471 14.8255 30.9431L19.1751 26.9087L26.1967 32.3847L26.3639 32.4847C26.8407 32.6959 27.2975 32.8015 27.7335 32.8015C28.0703 32.8015 28.3935 32.7383 28.7023 32.6119C29.7543 32.1799 30.1751 31.1775 30.2191 31.0639L35.4639 3.80234C35.7839 2.34634 35.3391 1.60554 34.9087 1.24154ZM15.5991 21.5999L13.1991 27.9999L10.7991 19.9999L29.1991 6.39994L15.5991 21.5999Z" fill="#303030"/>
                                    <g style="mix-blend-mode:color-dodge">
                                    <path d="M66.7609 41.2878C67.2586 41.1226 67.7654 40.9574 68.2631 40.7922C68.3368 40.94 68.4105 41.0878 68.4751 41.2356C67.9866 41.427 67.489 41.6096 67.0005 41.8009C66.9176 41.6269 66.8438 41.4617 66.7609 41.2878Z" fill="#F5F5F5"/>
                                    <path d="M67.5442 45.0182L67.4152 44.74L67.6825 44.7487L67.5442 45.0182Z" fill="#F5F5F5"/>
                                    <path d="M23.1692 44.9661C23.4641 44.7661 23.7499 44.5748 24.0448 44.3835L24.0728 44.4061C24.1912 44.5018 24.3017 44.591 24.4043 44.6878C24.2108 44.9572 24.0078 45.2271 23.8052 45.4965C23.5932 45.3226 23.3812 45.14 23.1692 44.9661Z" fill="#F5F5F5"/>
                                    <path d="M19.7225 48.0356C20.128 47.7574 20.5243 47.4791 20.9206 47.2009C21.068 47.3574 21.2155 47.5226 21.3537 47.6791C20.9666 47.9661 20.5703 48.2443 20.174 48.5313C20.0266 48.3661 19.8699 48.2008 19.7225 48.0356Z" fill="#F5F5F5"/>
                                    <path d="M21.1049 15.1487C21.2155 15.853 21.2432 16.0357 21.2708 16.2183C21.0773 16.2009 20.8837 16.1835 20.6902 16.1574C20.7639 15.9661 20.8468 15.7748 21.1049 15.1487Z" fill="#F5F5F5"/>
                                    <path d="M22.773 21.14C23.0126 21.9748 23.1878 22.5661 23.4919 23.5922C21.7869 23.0617 20.7455 22.7487 18.8285 22.1574C20.5611 21.7139 21.5381 21.4617 22.773 21.14Z" fill="#F5F5F5"/>
                                    <path d="M12.1193 31.2965C12.5801 31.1921 13.0132 30.9574 13.262 31.0617C14.4048 31.5487 15.4923 32.1313 17.3171 33.0356C15.6767 33.14 14.8472 33.1922 13.9994 33.2443C13.3635 32.5835 12.7828 31.9835 12.1193 31.2965Z" fill="#F5F5F5"/>
                                    <path d="M41.2602 4.81827C41.0206 4.98349 40.7902 5.14867 40.5598 5.31388C40.357 4.94867 40.1544 4.59218 39.9516 4.24435C40.1359 4.14 40.3202 4.04433 40.5138 3.93998C40.7626 4.23564 41.0114 4.52262 41.2602 4.81827Z" fill="#F5F5F5"/>
                                    <path d="M58.4112 47.4617L57.932 47.5487L57.9298 47.5399C57.8568 47.2559 57.7838 46.9719 57.72 46.6878L58.1992 46.6008C58.2729 46.8878 58.3374 47.1748 58.4112 47.4617Z" fill="#F5F5F5"/>
                                    <path d="M64.2542 22.8443C64.1805 22.8704 64.1528 22.8878 64.1159 22.9052C64.1528 22.8878 64.2081 22.8704 64.2542 22.8443Z" fill="#F5F5F5"/>
                                    <path d="M3.00465 19.453H4.48841C4.4517 19.6175 4.415 19.7907 4.3783 19.9639L4.37782 19.9661C4.11056 19.8617 3.83409 19.7574 3.00465 19.453Z" fill="#F5F5F5"/>
                                    <path d="M43.039 19.053C42.7256 19.0878 42.403 19.1313 42.0805 19.1661C42.0528 18.6965 42.0252 18.227 42.0068 17.7748C42.1819 17.74 42.3477 17.7139 42.5228 17.6791C42.6979 18.14 42.8639 18.5922 43.039 19.053Z" fill="#F5F5F5"/>
                                    <path d="M14.0915 16.7226C14.0731 16.9835 14.0639 17.2444 14.0455 17.5052L13.8151 17.4965V16.7139C13.9073 16.7226 13.9993 16.7226 14.0915 16.7226Z" fill="#F5F5F5"/>
                                    <path d="M40.9192 35.4183C40.9008 35.5052 40.8824 35.6009 40.864 35.6878C40.6889 35.627 40.5045 35.5661 40.3386 35.5052C40.5317 35.4705 40.7248 35.4445 40.9179 35.4184L40.9192 35.4183Z" fill="#F5F5F5"/>
                                    <path d="M59.4433 36.0704C59.4249 36.2878 59.4065 36.4965 59.3881 36.7052C58.9826 36.6182 58.5863 36.5226 58.19 36.4269C58.2084 36.3052 58.2269 36.1922 58.2453 36.0704H59.4433Z" fill="#F5F5F5"/>
                                    <path d="M59.6922 24.6009L59.7568 24.8183L59.5724 24.7922L59.6922 24.6009Z" fill="#F5F5F5"/>
                                    <path d="M39.4816 9.3226C39.371 9.33999 39.2328 9.38349 39.1406 9.34871C39.0577 9.31392 39.0392 9.18347 38.9931 9.09652L39.2696 8.94868C39.3342 9.07042 39.4079 9.20086 39.4816 9.3226Z" fill="#F5F5F5"/>
                                    <path d="M49.1768 4.69652C48.7713 6.38348 47.8865 7.02694 45.647 6.24433C46.2253 5.03533 46.8317 4.34355 47.8036 4.34548V1.76608C47.9326 1.7226 48.0524 1.68782 48.1814 1.65303V4.37834C48.4791 4.42747 48.8085 4.53225 49.1768 4.69652Z" fill="#F5F5F5"/>
                                    <path d="M31.7586 41.1661L31.1503 43.6182C30.9015 43.5487 30.6527 43.4791 30.4039 43.3922C30.6527 42.5922 30.9015 41.7835 31.1503 40.9835L31.7586 41.1661Z" fill="#F5F5F5"/>
                                    <path d="M6.4791 43.0878C6.4791 43.0008 6.52525 42.9052 6.55289 42.8182C6.61741 42.8443 6.72795 42.8791 6.73717 42.9052C6.73717 42.9922 6.69113 43.0878 6.66348 43.1748C6.59897 43.1487 6.4791 43.1139 6.4791 43.0878Z" fill="#F5F5F5"/>
                                    <path d="M12.2206 54.4357C12.2022 54.3748 12.3405 54.253 12.6446 53.8356C12.571 54.4434 12.5341 54.6871 12.4973 54.9302L12.4972 54.9313C12.3958 54.7661 12.2759 54.6096 12.2206 54.4357Z" fill="#F5F5F5"/>
                                    <path d="M6.82931 33.2965C6.92147 33.8357 7.0229 34.3835 7.11506 34.9226L6.39618 35.0269L6.23024 33.3835C6.433 33.3574 6.62656 33.3226 6.82931 33.2965Z" fill="#F5F5F5"/>
                                    <path d="M5.04455 39.8694L5.05068 39.8096C5.12441 39.8183 5.18889 39.8182 5.24418 39.8269V40.6443C5.15202 40.6356 5.0506 40.6356 4.95844 40.6269C4.99258 40.3773 5.01889 40.1202 5.04455 39.8694Z" fill="#F5F5F5"/>
                                    <path d="M48.2828 22.4965L47.5087 22.4009C47.6008 21.7313 47.6838 21.0617 47.776 20.4009C48.0156 20.4269 48.2551 20.4617 48.4947 20.4878C48.421 21.1574 48.3565 21.8269 48.2828 22.4965Z" fill="#F5F5F5"/>
                                    <path d="M48.4026 15.8443C48.0985 15.4704 47.8958 15.2096 47.5271 14.7487C48.0797 14.6096 48.4666 14.5227 49.4328 14.3057L49.4348 14.3052C48.9095 15.0878 48.6883 15.4183 48.4026 15.8443Z" fill="#F5F5F5"/>
                                    <path d="M49.6652 19.1922C49.7481 19.1922 49.8219 19.2009 49.9048 19.2009C49.8864 19.453 49.868 19.7052 49.8588 19.9661C49.7944 19.9574 49.7296 19.9574 49.6652 19.9574V19.1922Z" fill="#F5F5F5"/>
                                    <path d="M54.688 21.5226C54.7893 21.5139 54.9644 21.5748 54.9828 21.6356C55.0105 21.7226 54.9276 21.853 54.8907 21.9661C54.7893 21.9052 54.6511 21.8704 54.5958 21.7835C54.559 21.74 54.6419 21.5226 54.688 21.5226Z" fill="#F5F5F5"/>
                                    <path d="M47.951 -1.6513C47.693 -1.73825 47.4257 -1.81651 47.1677 -1.89478L47.352 -2.49477C47.61 -2.34695 47.8774 -2.19914 48.1354 -2.05131C48.0709 -1.91218 48.0155 -1.78173 47.951 -1.6513Z" fill="#F5F5F5"/>
                                    <path d="M67.2494 46.6357C67.149 46.8508 67.0396 47.0659 66.9302 47.2811L66.9267 47.2878L65.1296 46.7922C65.1835 46.4957 65.2374 46.2075 65.2913 45.9194L65.2956 45.8965C65.5813 45.7313 65.867 45.5748 66.3093 45.3226C66.641 45.7833 66.9454 46.2097 67.2494 46.6357Z" fill="#F5F5F5"/>
                                    <path d="M59.6554 45.1487C59.7936 44.6269 59.9042 44.2269 60.0332 43.7487C58.7154 44.0182 58.7155 44.0184 59.6554 45.1487Z" fill="#F5F5F5"/>
                                    <path d="M1.40108 3.73997C1.64069 3.87041 1.88956 3.99216 2.12917 4.1226L2.12873 4.12359C2.0828 4.22761 2.03688 4.33159 2.00014 4.42694C1.74209 4.33129 1.48407 4.24433 1.22602 4.14867C1.28132 4.00954 1.34578 3.87911 1.40108 3.73997Z" fill="#F5F5F5"/>
                                    <path d="M-1.03196 -0.0948086C-0.66332 -0.320896 -0.451308 -0.564368 -0.294636 -0.529585C-0.137964 -0.503498 -0.0457799 -0.190436 0.074028 0.000867844C-0.239316 -0.025219 -0.552728 -0.0513303 -1.03196 -0.0948086Z" fill="#F5F5F5"/>
                                    <path d="M33.7953 3.60086C34.0257 5.44433 33.2977 6.37476 31.8969 6.53998C31.648 6.27042 31.3899 6.00088 31.1319 5.74001C32.0166 5.02696 32.9106 4.3139 33.7953 3.60086Z" fill="#F5F5F5"/>
                                    <path d="M22.0449 8.65306C22.2108 8.7574 22.3675 8.87044 22.5334 8.97479C22.3951 9.21827 22.2846 9.47044 22.1095 9.67044C22.0726 9.70522 21.7869 9.49651 21.6118 9.40086C21.7592 9.14868 21.8974 8.90523 22.0449 8.65306Z" fill="#F5F5F5"/>
                                    <path d="M-2.13783 28.8791C-2.15626 28.9661 -2.18387 29.0531 -2.21152 29.14L-2.52494 29.0704C-2.39592 29.0095 -2.26686 28.94 -2.13783 28.8791Z" fill="#F5F5F5"/>
                                    <path d="M-8.37309 52.4016C-7.98118 52.75 -7.58464 53.1064 -7.19273 53.4548C-7.28388 53.5925 -7.37506 53.7303 -7.47082 53.8601C-7.88072 53.5327 -8.28768 53.193 -8.69758 52.8657C-8.58843 52.7069 -8.48223 52.5604 -8.37309 52.4016Z" fill="#F5F5F5"/>
                                    <path d="M-11.2121 54.9453L-11.0356 54.6944L-10.9095 54.9302L-11.2121 54.9453Z" fill="#F5F5F5"/>
                                    <path d="M-33.3544 16.4893C-33.0337 16.6447 -32.7252 16.7965 -32.412 16.9563L-32.4176 16.9919C-32.4413 17.1422 -32.4634 17.2825 -32.4959 17.4197C-32.8259 17.3869 -33.1612 17.3461 -33.4958 17.3053C-33.4511 17.0347 -33.399 16.7598 -33.3544 16.4893Z" fill="#F5F5F5"/>
                                    <path d="M-37.7361 15.0391C-37.2923 15.2511 -36.8532 15.4552 -36.4141 15.6593C-36.4759 15.8653 -36.5453 16.0755 -36.6117 16.2735C-37.0537 16.0818 -37.4929 15.8777 -37.9395 15.678C-37.8702 15.4677 -37.8054 15.2494 -37.7361 15.0391Z" fill="#F5F5F5"/>
                                    <path d="M-8.56396 -0.207197C-9.11865 0.240752 -9.26294 0.356041 -9.40726 0.471289C-9.48896 0.294987 -9.57065 0.11867 -9.64483 -0.0619793C-9.44229 -0.0937827 -9.23519 -0.117629 -8.56396 -0.207197Z" fill="#F5F5F5"/>
                                    <path d="M-12.9185 4.23311C-13.5216 4.85801 -13.9462 5.30534 -14.6827 6.08176C-15.0758 4.34001 -15.3254 3.28155 -15.7718 1.32579C-14.5214 2.60453 -13.8146 3.32449 -12.9185 4.23311Z" fill="#F5F5F5"/>
                                    <path d="M-27.0412 0.0849514C-26.7204 0.431842 -26.3005 0.689571 -26.2664 0.957239C-26.1168 2.1904 -26.0776 3.4235 -25.9484 5.45597C-26.859 4.08748 -27.3189 3.39528 -27.788 2.68709C-27.5336 1.80595 -27.3043 1.00308 -27.0412 0.0849514Z" fill="#F5F5F5"/>
                                    <path d="M10.4601 12.0826C10.1972 11.9577 9.93899 11.8408 9.68071 11.7239C9.89562 11.3657 10.103 11.0119 10.3029 10.6624C10.4854 10.7699 10.6604 10.8816 10.8475 10.9971C10.7159 11.3604 10.5918 11.7193 10.4601 12.0826Z" fill="#F5F5F5"/>
                                    <path d="M-17.8947 48.2575L-18.2096 47.886L-18.2031 47.8797C-17.9936 47.6745 -17.7841 47.4693 -17.5701 47.272L-17.2551 47.6435C-17.4668 47.8508 -17.6831 48.0502 -17.8947 48.2575Z" fill="#F5F5F5"/>
                                    <path d="M6.34607 41.009C6.28662 40.9582 6.25773 40.9429 6.22424 40.9197C6.25773 40.9429 6.30044 40.9822 6.34607 41.009Z" fill="#F5F5F5"/>
                                    <path d="M-21.3417 -13.7303L-20.5998 -12.4453C-20.7607 -12.3948 -20.929 -12.34 -21.0973 -12.2853L-21.0994 -12.2845C-21.1427 -12.5682 -21.1906 -12.8598 -21.3417 -13.7303Z" fill="#F5F5F5"/>
                                    <path d="M-0.978161 20.7404C-1.16495 20.4865 -1.36393 20.2289 -1.55533 19.9669C-1.1625 19.7082 -0.769634 19.4495 -0.387257 19.2074C-0.269581 19.3417 -0.164055 19.4722 -0.0463791 19.6065C-0.357952 19.9886 -0.666586 20.3584 -0.978161 20.7404Z" fill="#F5F5F5"/>
                                    <path d="M-13.4337 -5.49398C-13.6688 -5.37951 -13.8993 -5.257 -14.1344 -5.14252L-14.2421 -5.34641L-13.5643 -5.73772C-13.5258 -5.65356 -13.4798 -5.5738 -13.4337 -5.49398Z" fill="#F5F5F5"/>
                                    <path d="M-16.2107 27.0873C-16.2952 27.1148 -16.3873 27.1468 -16.4718 27.1743C-16.5066 26.9922 -16.5461 26.8021 -16.5763 26.628C-16.4497 26.7778 -16.3306 26.9321 -16.2115 27.0863L-16.2107 27.0873Z" fill="#F5F5F5"/>
                                    <path d="M-7.51347 43.4558C-7.71095 43.5485 -7.90085 43.6369 -8.0908 43.7253C-8.21825 43.3307 -8.33357 42.9396 -8.44888 42.5486C-8.33423 42.5037 -8.22712 42.4632 -8.11248 42.4183L-7.51347 43.4558Z" fill="#F5F5F5"/>
                                    <path d="M2.54389 37.9365L2.3879 38.1011L2.31831 37.9284L2.54389 37.9365Z" fill="#F5F5F5"/>
                                    <path d="M5.66996 12.7945C5.5996 12.7074 5.49282 12.6094 5.47686 12.5122C5.46551 12.423 5.56925 12.3418 5.62152 12.2584L5.88781 12.424C5.81463 12.5407 5.73853 12.6698 5.66996 12.7945Z" fill="#F5F5F5"/>
                                    <path d="M14.5239 18.8778C12.8602 19.3701 11.8605 18.9255 11.4185 16.5948C12.7547 16.491 13.657 16.6703 14.1413 17.5129L16.3751 16.2232C16.4772 16.3132 16.5673 16.3996 16.6619 16.494L14.3017 17.8566C14.408 18.139 14.482 18.4766 14.5239 18.8778Z" fill="#F5F5F5"/>
                                    <path d="M-25.7688 22.0279L-28.1966 22.7272C-28.2608 22.4769 -28.3249 22.2267 -28.374 21.9677C-27.5568 21.7832 -26.7321 21.5943 -25.9148 21.4098L-25.7688 22.0279Z" fill="#F5F5F5"/>
                                    <path d="M-40.0728 1.09606C-39.9975 1.05258 -39.8916 1.04473 -39.8025 1.0252C-39.7928 1.09411 -39.7677 1.20724 -39.7856 1.22827C-39.8609 1.27175 -39.9668 1.27969 -40.0559 1.29923C-40.0656 1.23032 -40.0954 1.1091 -40.0728 1.09606Z" fill="#F5F5F5"/>
                                    <path d="M-47.0296 11.7423C-46.9861 11.6959 -46.8115 11.7548 -46.2979 11.8095C-46.8611 12.0496 -47.0906 12.1395 -47.3195 12.2292L-47.3205 12.2296C-47.2282 12.0592 -47.1525 11.8772 -47.0296 11.7423Z" fill="#F5F5F5"/>
                                    <path d="M-31.4182 -3.49628C-31.839 -3.1469 -32.2628 -2.78515 -32.6836 -2.43577L-33.1334 -3.00616L-31.793 -3.97161C-31.6691 -3.80907 -31.5422 -3.65882 -31.4182 -3.49628Z" fill="#F5F5F5"/>
                                    <path d="M-38.0029 -1.75547L-37.948 -1.78009C-37.9186 -1.71189 -37.8864 -1.65607 -37.8663 -1.60383L-38.5742 -1.19513C-38.6127 -1.27929 -38.6634 -1.36714 -38.702 -1.4513C-38.4687 -1.54656 -38.2329 -1.65232 -38.0029 -1.75547Z" fill="#F5F5F5"/>
                                    <path d="M-1.33838 27.0035L-1.6426 26.2853C-1.01666 26.0303 -0.395307 25.7674 0.223103 25.5168C0.320319 25.7373 0.409953 25.9621 0.507168 26.1827C-0.109556 26.4536 -0.721655 26.7326 -1.33838 27.0035Z" fill="#F5F5F5"/>
                                    <path d="M4.48246 23.7812C4.65422 23.3308 4.7788 23.0248 4.9936 22.4752C5.3903 22.8842 5.659 23.1758 6.3301 23.904L6.33148 23.9055C5.39106 23.8419 4.99431 23.8155 4.48246 23.7812Z" fill="#F5F5F5"/>
                                    <path d="M2.21445 26.5485C2.25593 26.6203 2.28527 26.6885 2.32675 26.7604C2.09914 26.8705 1.87157 26.9807 1.64104 27.1031C1.61636 27.043 1.58396 26.9869 1.55175 26.9311L2.21445 26.5485Z" fill="#F5F5F5"/>
                                    <path d="M2.70764 32.0636C2.76586 32.147 2.80068 32.3291 2.75718 32.3755C2.69569 32.4429 2.54126 32.4363 2.42493 32.4609C2.42696 32.3427 2.38799 32.2056 2.43565 32.1142C2.45487 32.0606 2.6846 32.0237 2.70764 32.0636Z" fill="#F5F5F5"/>
                                    <path d="M19.4084 14.6423C19.3546 14.3753 19.2888 14.1048 19.2275 13.8421L19.8393 13.7017C19.8403 13.9991 19.846 14.3046 19.847 14.602C19.6942 14.6156 19.5536 14.6329 19.4084 14.6423Z" fill="#F5F5F5"/>
                                    <path d="M-12.7602 55.4986C-12.9967 55.5193 -13.2377 55.5321 -13.4787 55.5448L-13.4863 55.5452L-13.9556 53.7411C-13.6719 53.6395 -13.3954 53.5421 -13.119 53.4448L-13.097 53.437C-12.8111 53.6018 -12.5327 53.7709 -12.0931 54.028C-12.3262 54.5455 -12.5434 55.0223 -12.7602 55.4986Z" fill="#F5F5F5"/>
                                    <path d="M-15.2694 48.1785C-14.7485 48.0374 -14.3468 47.9331 -13.8681 47.8057C-14.7605 46.7992 -14.7605 46.7994 -15.2694 48.1785Z" fill="#F5F5F5"/>
                                    <path d="M-8.5356 -22.9755C-8.52875 -22.7028 -8.50976 -22.4264 -8.50291 -22.1537L-8.504 -22.1536C-8.61704 -22.1413 -8.73005 -22.1291 -8.831 -22.1133C-8.87719 -22.3846 -8.93088 -22.6515 -8.97707 -22.9228C-8.82893 -22.9445 -8.68374 -22.9539 -8.5356 -22.9755Z" fill="#F5F5F5"/>
                                    <path d="M-6.4311 -27C-6.05098 -26.7938 -5.73412 -26.7319 -5.68591 -26.5788C-5.63017 -26.4301 -5.85519 -26.1938 -5.96096 -25.9943C-6.09504 -26.2788 -6.22914 -26.5632 -6.4311 -27Z" fill="#F5F5F5"/>
                                    <path d="M7.78199 5.00912C6.30069 6.13039 5.13091 5.96513 4.28741 4.83459C4.39644 4.48431 4.50082 4.126 4.59771 3.77209C5.65759 4.18177 6.72211 4.59944 7.78199 5.00912Z" fill="#F5F5F5"/>
                                    <path d="M-2.46854 -2.64091C-2.47596 -2.44508 -2.49551 -2.25285 -2.50293 -2.05702C-2.78291 -2.055 -3.05657 -2.02466 -3.31733 -2.0763C-3.36588 -2.09083 -3.328 -2.44264 -3.33271 -2.64211C-3.0406 -2.6405 -2.76066 -2.64253 -2.46854 -2.64091Z" fill="#F5F5F5"/>
                                    <path d="M-32.0762 -13.4708C-32.1607 -13.4432 -32.2498 -13.4237 -32.339 -13.4041L-32.4354 -13.7104C-32.3182 -13.6291 -32.1934 -13.5521 -32.0762 -13.4708Z" fill="#F5F5F5"/>
                                    </g>
                                    </g>
                                    </svg>
                                    `,
                                    'Telegram',
                                    shareTelegram,
                                )
                              : ''}
                          ${this.mail
                              ? this.li(
                                    html`<svg
                                        width="19"
                                        height="16"
                                        viewBox="0 0 19 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M18.2985 0.772295C18.3331 0.559721 18.2553 0.344279 18.0942 0.20603C17.9331 0.0677815 17.7126 0.027394 17.5148 0.0998766L0.924484 6.17884C0.700955 6.26074 0.546639 6.4716 0.532333 6.71468C0.518026 6.95775 0.64649 7.18613 0.858788 7.29504L5.59888 9.72662C5.78906 9.82418 6.01561 9.80971 6.19252 9.6887L10.1273 6.99743L7.77123 10.0189C7.66749 10.152 7.62293 10.3236 7.64841 10.492C7.67388 10.6603 7.76706 10.81 7.90524 10.9045L15.0154 15.7677C15.1827 15.8821 15.3951 15.9017 15.5796 15.8197C15.7641 15.7377 15.8954 15.5653 15.9285 15.3618L18.2985 0.772295Z"
                                            fill="#94A1BD"
                                        />
                                    </svg>`,
                                    'Mail',
                                    shareMail,
                                )
                              : ''}
                          ${this.viber
                              ? this.li(
                                    html`<svg
                                        width="19"
                                        height="16"
                                        viewBox="0 0 19 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M18.2985 0.772295C18.3331 0.559721 18.2553 0.344279 18.0942 0.20603C17.9331 0.0677815 17.7126 0.027394 17.5148 0.0998766L0.924484 6.17884C0.700955 6.26074 0.546639 6.4716 0.532333 6.71468C0.518026 6.95775 0.64649 7.18613 0.858788 7.29504L5.59888 9.72662C5.78906 9.82418 6.01561 9.80971 6.19252 9.6887L10.1273 6.99743L7.77123 10.0189C7.66749 10.152 7.62293 10.3236 7.64841 10.492C7.67388 10.6603 7.76706 10.81 7.90524 10.9045L15.0154 15.7677C15.1827 15.8821 15.3951 15.9017 15.5796 15.8197C15.7641 15.7377 15.8954 15.5653 15.9285 15.3618L18.2985 0.772295Z"
                                            fill="#94A1BD"
                                        />
                                    </svg>`,
                                    'Viber',
                                    shareViber,
                                )
                              : ''}
                          ${this.whatsapp
                              ? this.li(
                                    html`<svg
                                        width="19"
                                        height="16"
                                        viewBox="0 0 19 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M18.2985 0.772295C18.3331 0.559721 18.2553 0.344279 18.0942 0.20603C17.9331 0.0677815 17.7126 0.027394 17.5148 0.0998766L0.924484 6.17884C0.700955 6.26074 0.546639 6.4716 0.532333 6.71468C0.518026 6.95775 0.64649 7.18613 0.858788 7.29504L5.59888 9.72662C5.78906 9.82418 6.01561 9.80971 6.19252 9.6887L10.1273 6.99743L7.77123 10.0189C7.66749 10.152 7.62293 10.3236 7.64841 10.492C7.67388 10.6603 7.76706 10.81 7.90524 10.9045L15.0154 15.7677C15.1827 15.8821 15.3951 15.9017 15.5796 15.8197C15.7641 15.7377 15.8954 15.5653 15.9285 15.3618L18.2985 0.772295Z"
                                            fill="#94A1BD"
                                        />
                                    </svg>`,
                                    'Whatsapp',
                                    shareWhatsapp,
                                )
                              : ''}
                          ${this.skype && this.skypeReady
                              ? this.li(
                                    html`<svg
                                        width="19"
                                        height="16"
                                        viewBox="0 0 19 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M18.2985 0.772295C18.3331 0.559721 18.2553 0.344279 18.0942 0.20603C17.9331 0.0677815 17.7126 0.027394 17.5148 0.0998766L0.924484 6.17884C0.700955 6.26074 0.546639 6.4716 0.532333 6.71468C0.518026 6.95775 0.64649 7.18613 0.858788 7.29504L5.59888 9.72662C5.78906 9.82418 6.01561 9.80971 6.19252 9.6887L10.1273 6.99743L7.77123 10.0189C7.66749 10.152 7.62293 10.3236 7.64841 10.492C7.67388 10.6603 7.76706 10.81 7.90524 10.9045L15.0154 15.7677C15.1827 15.8821 15.3951 15.9017 15.5796 15.8197C15.7641 15.7377 15.8954 15.5653 15.9285 15.3618L18.2985 0.772295Z"
                                            fill="#94A1BD"
                                        />
                                    </svg>`,
                                    'Skype',
                                    shareSkype,
                                )
                              : ''}
                      </ul>
                  `}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'app-web-share': WebShare;
    }
}
