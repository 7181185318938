// let videoEl;
let videoSoundEl;

function init(container = document) {
    // videoEl = Array.from(container.querySelectorAll('.js-main-video-trigger'));
    // videoEl.forEach((el) => {
    //     if (el) {
    //         el.addEventListener('click', onClick);
    //     }
    // });

    videoSoundEl = Array.from(container.querySelectorAll('.js-main-video-sound'));
    videoSoundEl.forEach((el) => {
        if (el) {
            el.addEventListener('click', onSoundClick);
        }
    });
}

function onSoundClick(this: HTMLElement) {
    const container = this.closest('.js-video-container-desktop');
    const video = container?.querySelector<HTMLMediaElement>('video');
    if (video && video.readyState === 4) {
        if (video.muted) {
            video.muted = false;
            this.classList.add('is-on');
        } else {
            video.muted = true;
            this.classList.remove('is-on');
        }
    }
}

// function onClick(this: HTMLElement, event: Event) {
//     const container = this.closest('.js-video-container-desktop');
//     const triggerContainer = this.closest('.js-main-video-trigger-container');
//     const video = container?.querySelector<HTMLMediaElement>('video');
//     if (video) {
//         // video.muted = true;
//         // video.controls = true;
//         video.play();

//         if (triggerContainer) triggerContainer.classList.remove('is-show');
//     }
// }

const _module = { init };

export default _module;
