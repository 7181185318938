import gsap from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';

gsap.registerPlugin(
    DrawSVGPlugin,
);

function init() {
    const triggers = document.querySelectorAll<HTMLElement>('.js-line-draw-container');

    triggers.forEach(trigger => {

        function drawLine(line: HTMLElement) {
            const drawTl = gsap.timeline({
                duration: 2
            });
            drawTl
                .fromTo(
                    line,
                    { drawSVG: '0 0'},
                    { drawSVG: '100%', duration: 12, transformOrigin:"50% 50%"},
                    "-=1",
                );
        }

        function drawIcon(icon: HTMLElement) {
            const drawTl = gsap.timeline({
                duration: 2
            });
            drawTl
                .fromTo(
                    icon,
                    { drawSVG: '0%'},
                    { drawSVG: '100%', duration: 1, transformOrigin:"100% 50%"},
                    "-=1.7",
                );
        }

        if (trigger) {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger,
                    start: 'top bottom',
                    invalidateOnRefresh: false,
                    onEnter: () => {
                        const lines = Array.from(trigger.querySelectorAll<HTMLElement>('.js-line-draw'));
                        const icons = Array.from(trigger.querySelectorAll<HTMLElement>('.js-icon-draw'));
                        lines.forEach(line => {
                            drawLine(line);
                        })
                        icons.forEach(icon => {
                            drawIcon(icon);
                        })
                    },
                },
            });
        }
    })

}

const _module = { init };

export default _module;
