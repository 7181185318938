import Hammer from 'hammerjs';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

function closePopup(trigger: HTMLElement) {
    const popup = trigger.closest<LitPopupElement>('app-lit-popup');
    if (popup) popup.close();
}

function init() {
    const mobileTouchTriggers = document.querySelectorAll<HTMLElement>('.js-mobile-close-popup');

    mobileTouchTriggers.forEach(trigger => {
        let hammertime = new Hammer(trigger);

        hammertime.on('pan', function(e: any) {
            if (e.additionalEvent == 'panleft' || e.additionalEvent == 'panright' || e.additionalEvent == 'pandown') {
                closePopup(trigger);
            }
        });
    })
}

const _module = { init };

export default _module;
