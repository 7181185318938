import gsap from 'gsap';

const map = new WeakMap<HTMLElement, any>();

export default function initHoverButtons() {
    const buttons = document.querySelectorAll('.js-hover-button');

    if (window.matchMedia('(any-hover: hover) and (hover: hover) and (pointer: fine)').matches) {
        buttons.forEach(button => {
            button.addEventListener('mouseenter', scaleButton);
            button.addEventListener('click', scaleSmallButton);
            button.addEventListener('mouseleave', ButtonLeave);
            button.addEventListener('mousemove', moveButton);
        })

        const buttonsSmall = document.querySelectorAll<HTMLElement>('.js-hover-sm-element');

        buttonsSmall.forEach(button => {
            button.addEventListener('mouseleave', ButtonLeave);
            button.addEventListener('mousemove', moveButton);
            button.addEventListener('mouseenter', () => {
                if (button.classList.contains('is-leave') && map.get(button)) {
                    map.get(button).kill();
                }
            });
        })
    }
}

function scaleButton(e: Event) {
    const targetElement = e.target as HTMLElement;
    const textButton = targetElement.querySelector<HTMLElement>('.js-hover-button-text');

    if (targetElement.classList.contains('is-leave') && map.get(targetElement)) {
        map.get(targetElement).kill();
    }

    if (textButton && targetElement.classList.contains('is-leave') && map.get(textButton)) {
        map.get(textButton).kill();
    }

    const tl = gsap.timeline({ repeat: 0 })

    tl.to(targetElement,{
            scale: 1.08,
            duration: 0.2,
            ease: 'Power4.in'
        })
        // .to(targetElement, {
        //     scale: 1,
        //     duration: 0.2,
        //     ease: 'Power4.out'
        // })
        ;
}

function scaleSmallButton(e: Event) {
    const targetElement = e.target as HTMLElement;

    const tl = gsap.timeline({ repeat: 0 })

    tl.to(targetElement,{
            scale: 0.96,
            duration: 0.2,
            ease: 'Power4.in'
        })
        ;
}


function moveButton(e: Event) {
    const targetElement = e.currentTarget as HTMLElement;
    const textButton = targetElement.querySelector('.js-hover-button-text');

    const offsetX = e.offsetX;
    const offsetY = e.offsetY;
    const { clientWidth, clientHeight } = targetElement;

    let xPos =( offsetX / clientWidth) - 0.5;
    let yPos = (offsetY / clientHeight) - 0.5;

    const tlMove = gsap.timeline({
        defaults: {
            duration: 1.2,
            ease: "Power4.out"
        }
    });

    if (textButton) {
        tlMove
        .to(targetElement, {
            x: xPos*40,
            y: yPos*40,
        }, 0)
        tlMove.to(textButton, {
            x: xPos*20,
            y: yPos*20,
        }, 0)
    } else {
        tlMove.to(targetElement, {
            x: xPos*20,
            y: yPos*20,
        })
    }
}

function ButtonLeave(e: Event) {
    const targetElement = e.target as HTMLElement;

    targetElement.classList.add('is-leave');

    setTimeout(() => {
        targetElement.classList.remove('is-leave');
    }, 1200);

    const textButton = targetElement.querySelector<HTMLElement>('.js-hover-button-text');

    const leaveTl = gsap.to(targetElement, {
        duration: 0.6,
        x: 0,
        y: 0,
        scale: 1,
        delay: 0.6
    });

    map.set(targetElement, leaveTl);

    if (textButton) {
        const leaveText = gsap.to(textButton, {
            duration: 0.6,
            x: 0,
            y: 0,
            delay: 0.6
        });
        map.set(textButton, leaveText);
    }
}
