function printPage() {
    window.print();
}

function init(container: Element | Document = document) {
    container.querySelectorAll('.js-print-page').forEach(el => {
        el.addEventListener('click', printPage);
    });
}

function destroy(container: Element | Document = document) {
    document.querySelectorAll('.js-print-page').forEach(el => {
        el.removeEventListener('click', printPage);
    });
}

const _module = { init, destroy };

export default _module;
