import gsap from 'gsap';

let trigger: gsap.plugins.ScrollTriggerInstance | null;

function init(container = document) {
    const blocks = Array.from(container.querySelectorAll('.js-product-bg'));
    if (!blocks.length) return;
    const img = blocks[0].querySelector('svg');

    if (window.matchMedia('(max-width: 1921px)').matches) {
        gsap.from(img, {
            yPercent: 17,
            scrollTrigger: {
                trigger: blocks[0],
                start: "top+=25% bottom",
                end: "top+=45% bottom",
                scrub: 0.6,
            }
        });
    } else if (window.matchMedia('(max-width: 2560px)').matches) {
        gsap.from(img, {
            yPercent: 20,
            scrollTrigger: {
                trigger: blocks[0],
                start: "top+=25% bottom",
                end: "top+=45% bottom",
                scrub: 0.6,
            }
        });
    }
}

function destroy(container = document) {
    if (trigger) {
        trigger.kill();
        trigger = null;
    }
}

export default {init, destroy}
