import * as Cookies from 'js-cookie';
import { CookiesAgreement } from './CookiesAgreement/CookiesAgreement';
import './LitPopupElement/LitPopupElement';
import { YandexMap } from './YandexMap/YandexMap';
import { WebShare } from './WebShare/WebShare';

export function initCustomElements() {
    if (Cookies.get('COOKIES_USAGE_ACCEPTED') !== 'true') {
        customElements.define('app-cookies-agreement', CookiesAgreement);
    }

    customElements.define('app-yandex-map', YandexMap);
    customElements.define('app-web-share', WebShare);
}
