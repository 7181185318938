import './webpack-imports';
import './sentry';
import './custom-elements';
import { applyPolyfills } from './polyfills';

import { contentAnimation } from './animations/to-index';
import reveal from './animations/reveal';
import { initCustomElements } from './custom-elements';
import gsap from 'gsap';
import hoverButtons from './animations/hover-button';
import parallax from './animations/parallax';
import menu from './inits/menu';
import toggleLitBtn from './inits/toggle-lit-btn';
import cursorProduct from './inits/cursor-product';
import { lazyload } from './inits/lazy-load';
import scrollTo from './inits/scrollto';
import drawLines from './animations/draw-lines';
import svgAnimations from './animations/svg-animations';
import sliderProducts from './inits/slider-products';
import sliderRecipes from './inits/slider-recipes';
import sliderReviews from './inits/slider-reviews';
import sliderInstagram from './inits/slider-instagram';
import sliderVideo from './inits/slider-video';
import video from './inits/video';
import playVideo from './inits/play-video';
import tabs from './inits/tabs';
import mapTabs from './inits/map-tabs';
import pagination from './inits/pagination';
import marketplace from './inits/marketplace';
import catalogFilters from './inits/catalog-filters';
import mobileTouch from './inits/mobile-touch';
import backPage from './inits/back-link';
import toggleClass from './inits/toggle-class';
import printPage from './inits/print-page';
import location from './inits/location';
import select from './inits/select';
import aboutForm from './inits/about-form';
import recipeLikes from './inits/recipe-likes';
import inputMasks from './inits/masks';
import buttonsProduct from './inits/buttons-product';

import preloader from './components/preloader';

document.documentElement.classList.add('js-ready');

gsap.config({ nullTargetWarn: false });

preloader.init();
reveal.init();
menu.init();
scrollTo.init();

document.addEventListener(
    'preloader-leave',
    () => {
        document.documentElement.classList.add('js-ready');

        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'auto'})
            sliderProducts.init();
        }, 100);

        const aboutFormMask = document.querySelector('.about-form__form');
        if (aboutFormMask) {
            inputMasks.init(aboutFormMask);
        }

        playVideo.init();
        contentAnimation();
        initCustomElements();
        lazyload.update();
        hoverButtons();
        parallax.init();
        toggleLitBtn.init();
        cursorProduct();
        drawLines.init();
        svgAnimations.init();
        sliderRecipes.init();
        sliderReviews.init();
        sliderInstagram.init();
        sliderVideo.init();
        video.init();
        tabs.init();
        mapTabs.init();
        marketplace.init();
        catalogFilters.init();
        mobileTouch.init();
        pagination.init();
        backPage.init();
        toggleClass.init();
        printPage.init();
        location.init();
        select.init();
        recipeLikes.init();
        aboutForm.init();
        buttonsProduct();
    },
    {
        once: true,
    },
);

// Always at the end
module.hot?.accept();
