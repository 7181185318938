import gsap from 'gsap';

export function contentAnimation() {
    const tl = gsap.timeline({
        onComplete: () => {
            document.documentElement.dispatchEvent(new Event('header-animation-complete'));
        },
    });

    tl.to('.a-preloader', {
        duration: 0.3,
        autoAlpha: 0,
    })
        .from('.a-header', {
            y: -30,
            duration: 0.4,
            opacity: 0,
        })
        .from(
            '.a-content',
            {
                duration: 0.8,
                opacity: 0,
            },
            '-=0.2',
        );
}
