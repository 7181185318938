import gsap from 'gsap';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin';

gsap.registerPlugin(
    MorphSVGPlugin
);

function init() {
    const tl = gsap.timeline({defaults: {duration: 2, repeat: -1, repeatDelay: 10, ease: 'none'}});
    const lines = document.querySelectorAll<SVGPathValue | MorphSVGconsts | undefined>(".line-1");

    lines.forEach(line => {
        if (line) {
            tl.to(line, {morphSVG: ".line-2"})
              .to(line, {morphSVG: ".line-3"})
              .to(line, {morphSVG: ".line-4"})
              .to(line, {morphSVG: ".line-5"})
              .to(line, {morphSVG: ".line-6"})
              .to(line, {morphSVG: line});
        }
    })

    const tlCircle = gsap.timeline({defaults: {duration: 2, repeat: -1, repeatDelay: 10, ease: 'none'}});
    const circles = document.querySelectorAll<SVGPathValue | MorphSVGVars | undefined>(".circle-1");

    circles.forEach(circle => {
        if (circle) {
            tlCircle.to(circle, {morphSVG: ".circle-2"})
              .to(circle, {morphSVG: ".circle-3"})
              .to(circle, {morphSVG: ".circle-4"})
              .to(circle, {morphSVG: circle});
        }
    })
}

const _module = { init };

export default _module;
