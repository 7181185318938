import Swiper from 'swiper';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

const SELECTOR = '.js-recipes-slider';

const map = new WeakMap<HTMLElement, any>();

function init(container: HTMLElement | Document = document) {

    const arrSlider = document.querySelectorAll<HTMLElement>(SELECTOR);

    arrSlider.forEach(slider => {
        if (window.matchMedia('(min-width: 651px)').matches) {
            const navigation = slider.querySelector<HTMLElement>('.js-slider-navigation');
            const slides = slider.querySelectorAll<HTMLElement>('.swiper-slide');

            function playSlide(slide: Element, action: 'enter' | 'leave' | 'leaveBack' | 'enterBack') {
                const photo = slide.querySelector<HTMLElement>('.js-product-photo');
                const titles = slide.querySelectorAll<HTMLElement>('.js-product-title');
                const opacityElements = slide.querySelectorAll<HTMLElement>('.js-product-opacity');

                if (photo) {
                    const tl = gsap.timeline({
                        duration: 0.1,
                        ease: 'none',
                    })

                    if (action === 'enter') {
                        tl.fromTo(photo, {xPercent: 100}, {xPercent: 0});
                    } else if (action === 'leave') {
                        tl.fromTo(photo, {xPercent: 0}, {xPercent: -100});
                    } else if (action === 'enterBack') {
                        tl.fromTo(photo, {xPercent: -100}, {xPercent: 0});
                    } else if (action === 'leaveBack') {
                        tl.fromTo(photo, {xPercent: 0},{xPercent: 100});
                    }
                }

                titles.forEach(title => {

                    if (title) {
                        if (action === 'enter' || action === 'enterBack') {
                            const tl = gsap.timeline({})
                                tl.fromTo(title, {
                                    "clip-path": "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
                                }, {
                                    "clip-path": "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
                                    duration: 0.4,
                                    delay: 0.5,
                                    ease: 'Power3.In'
                                });
                        } else if (action === 'leave' || action === 'leaveBack') {
                            const tl = gsap.timeline({})
                            tl.fromTo(title, {
                                "clip-path": "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
                            }, {
                                "clip-path": "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
                                duration: 0.3,
                                ease: 'Power4.Out'
                            });
                        }
                    }
                })

                opacityElements.forEach(el => {
                    if (el) {
                        const tl = gsap.timeline({
                            duration: 0.1,
                            ease: 'none',
                        })

                        if (action === 'enter' || action === 'enterBack') {
                            setTimeout(() => {
                                tl.fromTo(el, {autoAlpha: 0}, {autoAlpha: 1});
                            }, 400);
                        } else if (action === 'leave' || action === 'leaveBack') {
                            tl.fromTo(el, {autoAlpha: 1}, {autoAlpha: 0});
                        }
                    }
                })
            }

            let swiper = new Swiper(slider, {
                slidesPerView: 1,
                speed: 0,
                watchSlidesProgress: true,
                virtualTranslate: true,
                on: {
                    beforeInit: () => {
                        if (slides.length > 1) {
                            if (navigation) createNavigation(navigation);
                        }

                    },
                    init: (swiper) => {
                        const prevBtn = slider.querySelector<HTMLElement>('.js-slide-prev');
                        const nextBtn = slider.querySelector<HTMLElement>('.js-slide-next');

                        const photos = swiper.slides.map((el) =>
                            el.querySelector<HTMLElement>('.js-product-photo'),
                        );
                        const titles = slider.querySelectorAll<HTMLElement>('.js-product-title');
                        const opacityElements = slider.querySelectorAll<HTMLElement>('.js-product-opacity');

                        photos.forEach((photo) => {
                            if (photo) {
                                gsap.set(photo, {xPercent: 100})
                            }
                        });

                        titles.forEach((title) => {
                            if (title) {
                                const titleContent = title.querySelectorAll('.js-product-title-content');

                                gsap.to(title, {"clip-path": "inset(0 0 100% 0)"});
                                titleContent.forEach(content => {
                                })
                            }
                        });

                        opacityElements.forEach((el) => {
                            if (el) {
                                gsap.set(el, {autoAlpha: 0})
                            }
                        });

                        prevBtn?.addEventListener('click', () => {
                            swiper.slidePrev();
                            nextBtn?.removeAttribute('disabled');
                            if (swiper.activeIndex === 0) {
                                prevBtn.setAttribute('disabled', '');
                            } else {
                                prevBtn.removeAttribute('disabled');
                            }

                            disableBtn();
                        });

                        nextBtn?.addEventListener('click', () => {
                            swiper.slideNext();
                            prevBtn?.removeAttribute('disabled');
                            if (swiper.activeIndex === swiper.slides.length - 1) {
                                nextBtn.setAttribute('disabled', '');
                            } else {
                                nextBtn.removeAttribute('disabled');
                            }

                            disableBtn();
                        });

                        function disableBtn() {
                            if (nextBtn && prevBtn) {
                                nextBtn.style.pointerEvents = 'none';
                                prevBtn.style.pointerEvents = 'none';
                                setTimeout(() => {
                                    nextBtn.style.pointerEvents = 'auto';
                                    prevBtn.style.pointerEvents = 'auto';
                                }, 600);
                            }
                        }
                    },
                    afterInit: (swiper) => {
                        playSlide(swiper.slides[swiper.activeIndex], 'enter');
                    },
                    slideChange: (swiper) => {
                        if (swiper.previousIndex < swiper.activeIndex) {
                            playSlide(swiper.slides[swiper.previousIndex], 'leave');

                            playSlide(swiper.slides[swiper.activeIndex], 'enter');
                        } else {
                            playSlide(swiper.slides[swiper.previousIndex], 'leaveBack');

                            playSlide(swiper.slides[swiper.activeIndex], 'enterBack');
                        }

                        const video = swiper.slides[swiper.previousIndex].querySelector('iframe, video');
                        if (video) video.remove();
                    },
                    slideChangeTransitionEnd: function() {
                        if (swiper.activeIndex == 0 || swiper.activeIndex == swiper.slides.length - 1) {

                            setTimeout(() => {
                                swiper.mousewheel.disable();
                            }, 1000);
                        }
                    },
                }
            });

            map.set(slider, swiper);
        } else {
            let swiper = new Swiper(slider, {
                slidesPerView: 1,
                speed: 400,
                spaceBetween: 10
            });

            map.set(slider, swiper);
        }
    });
}

function createNavigation(parent: HTMLElement) {
    parent.innerHTML = `
        <button class="recipes-slider__button button-round-mask js-slide-prev" disabled aria-label="предыдущий слайд">
            <span class="button-round__icon">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.7068 1.40499C12.8356 1.29733 12.9101 1.13809 12.9101 0.970211C12.9101 0.536524 12.4428 0.263597 12.065 0.47667L0.603327 6.94199C-0.0873124 7.33157 -0.0714857 8.33155 0.631135 8.69908L15.9572 16.7158C16.255 16.8716 16.6227 16.7514 16.771 16.4497C16.9026 16.1821 16.8168 15.8584 16.5699 15.691L6.09826 8.59048C5.54822 8.21752 5.50826 7.42172 6.01816 6.99553L12.7068 1.40499Z" fill="#FDFDFD"/>
                </svg>
            </span>
        </button>
        <button class="recipes-slider__button recipes-slider__button--next button-round-mask js-slide-next" aria-label="следующий слайд">
            <span class="button-round__icon">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.7068 1.40499C12.8356 1.29733 12.9101 1.13809 12.9101 0.970211C12.9101 0.536524 12.4428 0.263597 12.065 0.47667L0.603327 6.94199C-0.0873124 7.33157 -0.0714857 8.33155 0.631135 8.69908L15.9572 16.7158C16.255 16.8716 16.6227 16.7514 16.771 16.4497C16.9026 16.1821 16.8168 15.8584 16.5699 15.691L6.09826 8.59048C5.54822 8.21752 5.50826 7.42172 6.01816 6.99553L12.7068 1.40499Z" fill="#FDFDFD"/>
                </svg>
            </span>
        </button>
    `;
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>(SELECTOR)).forEach((el) => {
        const slider = map.get(el);

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
