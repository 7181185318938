import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const SELECTOR = '.js-slider-products';

function playSlide(slide: Element, action: 'enter' | 'leave' | 'leaveBack' | 'enterBack') {
    const photo = slide.querySelector<HTMLElement>('.js-product-photo');
    const titles = slide.querySelectorAll<HTMLElement>('.js-product-title');
    const opacityElements = slide.querySelectorAll<HTMLElement>('.js-product-opacity');
    const backGround = slide.querySelector<HTMLElement>('.js-product-bg');
    const backGroundPhoto = slide.querySelector<HTMLElement>('.js-product-bg-photo');
    const bgElementsContainer = slide.querySelector<HTMLElement>('.js-product-bg-element-container');

    if (photo) {
        const tl = gsap.timeline({
            duration: 0.1,
            ease: 'none',
        })

        if (action === 'enter') {
            tl.fromTo(photo, {yPercent: 100}, {yPercent: 0});
        } else if (action === 'leave') {
            tl.fromTo(photo, {yPercent: 0}, {yPercent: -100});
        } else if (action === 'enterBack') {
            tl.fromTo(photo, {yPercent: -100}, {yPercent: 0});
        } else if (action === 'leaveBack') {
            tl.fromTo(photo, {yPercent: 0},{yPercent: 100});
        }
    }

    titles.forEach(title => {
        if (title) {
            const titleContent = title.querySelectorAll('.js-product-title-content');

            titleContent.forEach(content => {
                const tl = gsap.timeline({
                    duration: 0.1,
                    ease: 'none',
                })

                if (action === 'enter') {
                    tl.fromTo(content, {yPercent: 100}, {yPercent: 0});
                } else if (action === 'leave') {
                    tl.fromTo(content, {yPercent: 0}, {yPercent: -100});
                } else if (action === 'enterBack') {
                    tl.fromTo(content, {yPercent: -100}, {yPercent: 0});
                } else if (action === 'leaveBack') {
                    tl.fromTo(content, {yPercent: 0},{yPercent: 100});
                }
            })
        }
    })

    opacityElements.forEach(el => {
        if (el) {
            const tl = gsap.timeline({
                duration: 0.1,
                ease: 'none',
            })

            if (action === 'enter' || action === 'enterBack') {
                setTimeout(() => {
                    tl.fromTo(el, {autoAlpha: 0}, {autoAlpha: 1});
                }, 400);
            } else if (action === 'leave' || action === 'leaveBack') {
                tl.fromTo(el, {autoAlpha: 1}, {autoAlpha: 0});
            }
        }
    })

    if (backGround) {
        const tl = gsap.timeline({
            duration: 0.1,
            ease: 'none',
        })

        if (action === 'enter') {
            tl.fromTo(backGround, {
                yPercent: 112,
                xPercent: 112,
                rotate: -90
            }, {
                yPercent: 0,
                xPercent: 0,
                rotate: 0
            });
        } else if (action === 'leave') {
            tl.fromTo(backGround, {
                yPercent: 0,
                xPercent: 0,
                rotate: 0
            }, {
                yPercent: -112,
                xPercent: 112,
                rotate: 90
            });
        } else if (action === 'enterBack') {
            tl.fromTo(backGround, {
                yPercent: -112,
                xPercent: 112,
                rotate: 90
            }, {
                yPercent: 0,
                xPercent: 0,
                rotate: 0
            });
        } else if (action === 'leaveBack') {
            tl.fromTo(backGround, {
                yPercent: 0,
                xPercent: 0,
                rotate: 0
            },{
                yPercent: 112,
                xPercent: 112,
                rotate: -90
            });
        }
    }

    if (backGroundPhoto) {
        const tl = gsap.timeline({
            duration: 0.1,
            ease: 'none',
            delay: 0.2
        })

        if (action === 'enter') {
            tl.fromTo(backGroundPhoto, {
                yPercent: 60,
                xPercent: 50,
                rotate: -90,
            }, {
                yPercent: 0,
                xPercent: 0,
                rotate: 0,
            });
        } else if (action === 'leave') {
            tl.fromTo(backGroundPhoto, {
                yPercent: 0,
                xPercent: 0,
                rotate: 0,
            }, {
                yPercent: -60,
                xPercent: 50,
                rotate: 90,
            });
        } else if (action === 'enterBack') {
            tl.fromTo(backGroundPhoto, {
                yPercent: -60,
                xPercent: 50,
                rotate: 90,
            }, {
                yPercent: 0,
                xPercent: 0,
                rotate: 0,
            });
        } else if (action === 'leaveBack') {
            tl.fromTo(backGroundPhoto, {
                yPercent: 0,
                xPercent: 0,
                rotate: 0,
            },{
                yPercent: 60,
                xPercent: 50,
                rotate: -90,
            });
        }
    }

    if (bgElementsContainer) {
        const tl = gsap.timeline({
            duration: 0.1,
            ease: 'none',
        })

        if (action === 'enter') {
            tl.fromTo(bgElementsContainer, {xPercent: 120}, {xPercent: 0});
        } else if (action === 'leave') {
            tl.fromTo(bgElementsContainer, {xPercent: 0}, {xPercent: -120});
        } else if (action === 'enterBack') {
            tl.fromTo(bgElementsContainer, {xPercent: -120}, {xPercent: 0});
        } else if (action === 'leaveBack') {
            tl.fromTo(bgElementsContainer, {xPercent: 0},{xPercent: 120});
        }
    }
}

function initSlider() {
    const productsSlider = document.querySelector<HTMLElement>(SELECTOR);
    if (!productsSlider) return;
    const slides =  Array.from(productsSlider.querySelectorAll<HTMLElement>('.js-products-slide-inner'));
    productsSlider.style.setProperty('--height-block', `${(slides.length + 1) * 100}vh`);
    const paginationBullets = Array.from(productsSlider.querySelectorAll<HTMLElement>('.js-pagianation-bullet'));
    const paginationToggle = productsSlider.querySelector<HTMLElement>('.js-pagianation-toggle');
    const paginationToggleSvg = paginationToggle?.querySelector<HTMLElement>('.js-pagination-icon');
    const paginationToggleSvgPath = paginationToggle?.querySelector<HTMLElement>('.js-pagination-icon path');
    const sliderLine = productsSlider.querySelector<HTMLElement>('.js-product-slider-line');
    const sliderLineSvgPath = productsSlider.querySelector<HTMLElement>('.js-product-slider-line path');
    let currentSection = slides[0];
    let curSectionWrapper: HTMLElement | null = currentSection.closest('.js-products-slide');
    curSectionWrapper?.classList.add('is-visible');

    const photos = slides.map((el) =>
        el.querySelector<HTMLElement>('.js-product-photo'),
    );
    const titles = productsSlider.querySelectorAll<HTMLElement>('.js-product-title');
    const opacityElements = productsSlider.querySelectorAll<HTMLElement>('.js-product-opacity');
    const backGrounds = slides.map((el) =>
        el.querySelector<HTMLElement>('.js-product-bg'),
    );
    const backGroundPhoto = slides.map((el) =>
        el.querySelector<HTMLElement>('.js-product-bg-photo'),
    );
    const bgElementsContainer = slides.map((el) =>
        el.querySelector<HTMLElement>('.js-product-bg-element-container'),
    );

    photos.forEach((photo) => {
        if (photo) {
            gsap.set(photo, {yPercent: 100})
        }
    });

    titles.forEach((title) => {
        if (title) {
            const titleContent = title.querySelectorAll('.js-product-title-content');

            titleContent.forEach(content => {
                gsap.set(content, {yPercent: 100});
            })
        }
    });

    opacityElements.forEach((el) => {
        if (el) {
            gsap.set(el, {autoAlpha: 0})
        }
    });

    backGrounds.forEach((el) => {
        if (el) {
            gsap.set(el, {
                yPercent: 100
            })
        }
    });

    backGroundPhoto.forEach((el) => {
        if (el) {
            gsap.set(el, {
                yPercent: 100
            })
        }
    });

    bgElementsContainer.forEach((el) => {
        if (el) {
            gsap.set(el, {
                xPercent: 100
            })
        }
    });

    const tl = gsap.timeline();
    playSlide(slides[0], 'enter');

    if (paginationBullets[0]) paginationBullets[0].classList.add('is-active');
    paginationBullets.forEach((bullet, i) => {
        bullet.addEventListener('click', () => {
            const activeBullet = productsSlider.querySelector<HTMLElement>('.js-pagianation-bullet.is-active')
            if (activeBullet) {
                const activeIndex = paginationBullets.indexOf(activeBullet);
                if (i > activeIndex) {
                    setSection(slides[i], 1, i)
                } else if (i < activeIndex) {
                    setSection(slides[i], -1, i)
                }
            }
        })
    })

    slides.forEach((slide, i) => {
        const trigger = slide.dataset.trigger ? document.getElementById(slide.dataset.trigger) : null;
        if (trigger) {
            ScrollTrigger.create({
                trigger,
                start: 'top top',
                end: () => `+=${window.innerHeight}`,
                toggleActions: 'play pause reverse pause',
                onToggle: (self) => {
                    self.isActive && setSection(slide, self.direction, i);
                },
            });
        }

    });
    function setSection(newSection: HTMLElement, direction: number, i: number) {
        if (newSection !== currentSection) {
            setTimeout(() => {
                currentSection.closest<HTMLElement>('.js-products-slide')?.classList.remove('is-visible')
                currentSection = newSection;
            }, 600);
            const newSectionWrapper = newSection.closest<HTMLElement>('.js-products-slide');
            if (newSectionWrapper) newSectionWrapper.style.zIndex = '8';

            newSection.style.zIndex = '8';
            newSectionWrapper?.classList.add('is-visible');
            if (curSectionWrapper) curSectionWrapper.style.zIndex = '';
            if (direction === 1) {
                playSlide(currentSection, 'leave');
                playSlide(newSection, 'enter');
            } else {
                playSlide(currentSection, 'leaveBack');
                playSlide(newSection, 'enterBack');
            }
            curSectionWrapper = newSectionWrapper;

            const tl = gsap.timeline();
            let color = '#56C0BD';
            let colorLine = '#fc8200';

            switch ( i + 1 ) {
                case 1:
                case 7:
                    color = '#fc8200';
                    colorLine = '#fc8200';
                    break;
                case 2:
                case 8:
                    color = '#56c0bd';
                    colorLine = '#54bebb';
                    break;
                case 3:
                case 9:
                    color = '#d5f32c';
                    colorLine = '#497b1e';
                    break;
                case 4:
                case 10:
                    color = '#eb7680';
                    colorLine = '#941b27';
                    break;
                case 5:
                case 11:
                    color = '#f09122';
                    colorLine = '#c95604';
                    break;
                case 6:
                case 13:
                    color = '#e7f35c';
                    colorLine = '#47468e';
                    break;
                default:
                    color = '#fc8200';
                    colorLine = '#fc8200';
            }

            paginationBullets.forEach(el => el.classList.remove('is-active'));
            paginationBullets[i].classList.add('is-active');

            if (paginationToggleSvg && paginationToggleSvgPath) {
                tl
                    .to(paginationToggle, {
                        yPercent: 100 * i,
                }, 0)
                    .to(paginationToggleSvg, {
                        scale: 1,
                        duration: 0.3
                }, 0)
                    .to(paginationToggleSvg, {
                        scale: 0.8,
                        delay: 0.3,
                }, 0)
                    .to(paginationToggleSvgPath, {
                        fill: color
                }, 0)
            }

            if (sliderLine) {
                const tlLine = gsap.timeline();

                tlLine
                    .to(sliderLine, {
                        xPercent: -11.5 * i
                    })
                    .to(sliderLineSvgPath, {
                        stroke: colorLine
                    }, 0)
            }
        }
    }
}

function init(container: HTMLElement | Document = document) {
    if (window.matchMedia('(min-width: 1025px)').matches) {
        initSlider();
    }
}

function destroy(container: HTMLElement | Document = document) {
}

const _module = { init, destroy };

export default _module;
