import { LitElement, css, html, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import * as Cookies from 'js-cookie';
import darkBtn from './img/mask-dark-button.svg';
import lightBtn from './img/mask-light-button.svg';

import _styles from './CookiesAgreement.ce.scss';

export interface CookiesAgreement {
    revealTimeout: number;
    _isVisible: boolean;
    _timer: NodeJS.Timeout;
}

/**
 * @attr {Number} reveal-timeout - Через сколько секунд сообщение покажется пользователю
 *
 * @slot - Контент в формате HTML
 */
export class CookiesAgreement extends LitElement {
    constructor() {
        super();
        this._acceptCookiesUsage = this._acceptCookiesUsage.bind(this);

        this.revealTimeout = this.getAttribute('reveal-timeout')
            ? parseFloat(this.getAttribute('reveal-timeout') as string)
            : 5000;
        this._isVisible = false;
    }

    static get properties() {
        return {
            revealTimeout: {
                type: Number,
                attribute: 'reveal-timeout',
            },
            _isVisible: {
                type: Boolean,
                attribute: false,
            },
        };
    }

    static get styles() {
        return css`
            ${unsafeCSS(_styles)}
        `;
    }

    connectedCallback() {
        super.connectedCallback();
        this._timer = setTimeout(() => {
            this._isVisible = true;
        }, this.revealTimeout);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        clearTimeout(this._timer);
    }

    close() {
        this._isVisible = false;
    }

    protected _acceptCookiesUsage() {
        Cookies.set('COOKIES_USAGE_ACCEPTED', 'true', {
            expires: 365, // days
        });
        this.close();
    }


    render() {

        return html`
            <div class="${classMap({ banner: true, 'banner--visible': this._isVisible })}">
                <div class="banner__text">
                    <slot></slot>
                </div>
                <div class="banner__bottom">
                    <a href="/cookie/" class="button banner__button button--light js-hover-button" target="_blank" rel="noopener">
                        <span class="button__bg"><img src=${lightBtn}></span>
                        <span class="button__text js-hover-button-text">Узнать больше</span>
                    </a>
                    <button class="button banner__button button--dark js-hover-button" @click="${this._acceptCookiesUsage}">
                        <span class="button__bg"><img src=${darkBtn}></span>
                        <span class="button__text js-hover-button-text">Принять</span>
                    </button>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'app-cookies-agreement': CookiesAgreement;
    }
}
