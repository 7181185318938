import ContentList from '@chipsadesign/content-list';
import ContentListFilter from '@chipsadesign/content-list/dist/modules/ContentListFilter';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';
import serialize from 'form-serialize';
import delegate from 'delegate';
import axios from 'axios';

let delegation: any;

const header = document.querySelector('.js-header');

function onOpen() {
    header?.classList.add('header--location-opened');
}

function onCloseComplete() {
    header?.classList.remove('header--location-opened');
}

function fetchLocation(event: any) {

    const target = event.delegateTarget as HTMLAnchorElement;
    const formFilters: HTMLFormElement | null = target.closest('.js-location-form');
    const url = formFilters?.action;

    let dataForm;

    if (formFilters) dataForm = serialize(formFilters, { hash: true });
    if (url) getRequest( url, dataForm, formFilters);
}

export async function getRequest(url: string, dataForm: any = '', formFilters: HTMLElement) {
    const containerContent = formFilters.closest('.js-location');
    containerContent?.classList.add('chps-content-list--loading');

    const response = await axios.get(url, {
        params: dataForm,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
    })
        .then(response => response.data)
        .then(data => {
            if (data.success) location.reload();

        })
        .catch((err) => {
            if (err.name !== 'AbortError') {
                throw err;
            }
        })
        .finally(() => {
            setTimeout(() => {
                containerContent?.classList.remove('is-loading');
            }, 300);
        });
}

function init() {
    const container = document.querySelector<HTMLElement>('.js-location');
    const filterContainer = container?.querySelector<HTMLElement>('.js-location-filter');

    if (container && filterContainer) {
        const contentList = new ContentList(container, {
            modules: [ContentListFilter],
            pushState: false,
            filter: {
                el: filterContainer,
                filterOnInputChange: true,
                inputDebounceTime: 500,
            },
            onFetchSuccess: (instance, response) => {
                if (instance.contentEl && response.data.contentHtml) {
                    instance.contentEl.innerHTML = response.data.contentHtml;
                }
            },
        });
    }

    delegation = delegate(container, '.js-location-trigger', 'change', fetchLocation);

    const cityPopup = document.querySelector<LitPopupElement>('[data-lit-popup="city-popup"]');

    if (cityPopup) {
        cityPopup.addEventListener('open', onOpen);
        cityPopup.addEventListener('close-complete', onCloseComplete);

        const scrollToButtons = Array.from(cityPopup.querySelectorAll('[data-scrollto]'));
        scrollToButtons.forEach((btn) =>
            btn.addEventListener('click', () => {
                cityPopup.close();
            }),
        );
    }
}

const _module = { init };

export default _module;






