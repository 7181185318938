import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

const header = document.querySelector('.js-header');
const menuBtn = document.querySelector<HTMLElement>('.js-menu-btn');
let btnTimeout: NodeJS.Timeout;

function onOpen() {
    header?.classList.add('header--menu-opened');

    if (menuBtn) {
        menuBtn.classList.add('is-active');
        btnTimeout = setTimeout(() => {
            menuBtn.removeAttribute('data-lit-popup-open');
            menuBtn.setAttribute('data-lit-popup-close', 'menu');
        }, 1);
    }
}

function onCloseComplete() {
    clearTimeout(btnTimeout);
    header?.classList.remove('header--menu-opened');

    if (menuBtn) {
        menuBtn.classList.remove('is-active');
        menuBtn.removeAttribute('data-lit-popup-close');
        menuBtn.setAttribute('data-lit-popup-open', 'menu');
    }
}

function init() {
    const menu = document.querySelector<LitPopupElement>('.js-menu');

    if (menu) {
        menu.addEventListener('open', onOpen);
        menu.addEventListener('close-complete', onCloseComplete);

        const scrollToButtons = Array.from(menu.querySelectorAll('[data-scrollto]'));
        scrollToButtons.forEach((btn) =>
            btn.addEventListener('click', () => {
                menu.close();
            }),
        );
    }
}

const _module = { init };

export default _module;
