import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

function generateYoutubeEmbedURL(id: string) {
    const query = '?rel=0&showinfo=0&autoplay=1';
    return `https://www.youtube.com/embed/${id}${query}`;
}

let iframe: HTMLElement;
let iframeContainer;
let videoContainer;
let videoEl;

function createIframe(id: string, isYoutube: boolean) {
    if (isYoutube) {
        iframe = document.createElement('iframe');
        iframe.classList.add('video');
        iframe.classList.add('video-iframe');
        iframe.setAttribute('allowfullscreen', '');
        iframe.setAttribute('allow', 'autoplay');
        iframe.setAttribute('src', generateYoutubeEmbedURL(id));
        return iframe;
    } else {
        const video = document.createElement('video');
        const source = document.createElement('source');
        video.classList.add('video');
        video.setAttribute('allowfullscreen', '');
        video.setAttribute('autoplay', '');
        video.setAttribute('controls', '');
        source.setAttribute('src', id);
        video.appendChild(source);
        return video;
    }
}

function init(container = document) {
    videoEl = Array.from(container.querySelectorAll('.js-video-play'));
    videoEl.forEach((el) => {
        if (el) {
            el.addEventListener('click', onClick);
        }
    });

    document.addEventListener('fullscreenchange', (e) => {
        const fullscreenElement =
            document.fullscreenElement || document.webkitFullscreenElement || document.mozCancelFullScreen;

        if (!fullscreenElement) {
            iframe.remove();
        }
    });
}

function onClick(this: HTMLElement, event: Event) {
    iframeContainer = this.closest('.js-video-container');
    this.classList.add('is-full');
    event.preventDefault();
    const { videoId } = this.dataset;
    const { videoSrc } = this.dataset;

    if (videoId) {
        iframe = createIframe(videoId, true);
    }

    if (videoSrc) {
        iframe = createIframe(videoSrc, false);
    }

    if (this.classList.contains('js-video-popup')) {
        const videoContainer = document.querySelector('.js-video-inner-popup');
        if (videoContainer) {
            videoContainer.appendChild(iframe);
            const popup = videoContainer.closest<LitPopupElement>('app-lit-popup');

            popup?.open();

            popup?.addEventListener('close-complete', () => {
                videoContainer.innerHTML = '';
            });
        }
    } else if (iframeContainer) {
        iframeContainer.appendChild(iframe);

        if (!this.classList.contains('js-not-full')) {
            if (iframe) {
                if (iframe.requestFullscreen) {
                    iframe.requestFullscreen();
                } else if (iframe.webkitRequestFullScreen) {
                    iframe.webkitRequestFullScreen();
                }
            }
        }
    }
}

const _module = { init };

export default _module;
